import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NavLink } from '../NavLink'

export const LogoutLink = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigate('/logout')
  }

  return (
    <NavLink
      path={'/logout'}
      label={t('logout')}
      icon={'logout'}
      allow
      onClick={onClick}
      className={'link_icon_stroke'}
    />
  )
}
