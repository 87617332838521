import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Divider, Navbar, ScrollArea, Space, Title } from '@mantine/core'
import { Grid } from '@mantine/core'
import { LogoIcon } from '@/components/Elements'
import { useAuth } from '@/features/auth'
import { BackLink } from '@/features/header/layout/BackLink'
import { Support } from '@/features/header/layout/Support/Support'
import { useUser } from '@/features/user'
import { GeneralLinks } from './GeneralLinks'
import { LogoutLink } from './LogoutLink'

interface IProps {
  onNavLinkClick?: () => void
}

export const NavbarContent = ({ onNavLinkClick }: IProps) => {
  const { t } = useTranslation()
  const { isLoggedIn } = useAuth()
  const { getName, getBookingListEntryPath } = useUser()

  const onLinkClick = () => {
    onNavLinkClick && onNavLinkClick()
  }

  return (
    <>
      <Navbar.Section>
        <Anchor component={Link} to={getBookingListEntryPath()} onClick={onLinkClick}>
          <LogoIcon />
        </Anchor>
      </Navbar.Section>

      {isLoggedIn && (
        <Navbar.Section mt={'lg'}>
          <Grid>
            <Grid.Col span={10}>
              {getName() && <Title order={3}>{getName()}</Title>}

              <Anchor
                component={Link}
                to={'/profile'}
                className={'font-bold'}
                onClick={onLinkClick}
              >
                {t('view_profile')}
              </Anchor>
            </Grid.Col>
            <Grid.Col span={2}>
              <Support />
            </Grid.Col>
          </Grid>
        </Navbar.Section>
      )}
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs" mt={'lg'}>
        <Space h={'xs'} />
        <GeneralLinks onLinkClick={onLinkClick} />
        <Space h={'xs'} />
      </Navbar.Section>

      <Navbar.Section>
        <Divider mb={'xs'} />
        {isLoggedIn ? <LogoutLink /> : <BackLink />}
      </Navbar.Section>
    </>
  )
}
