import { debounce } from 'lodash'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Grid, Group } from '@mantine/core'
import { Alert, Icon } from '@/components/Elements'
import { ZipInputController } from '@/components/FormElements'
import { HOTEL_TYPE } from '@/const/hotel'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingLocation } from '@/features/bookings/hooks'
import { useLocationGeocode } from '@/features/location/hooks'
import { BookingLocation } from '../../../types'
import { HotelFormSection } from './FormSections'

export type LocationFormSectionValues = {
  //city_id: number | string | null
  //city_type: string
  //city_description: string
  is_hotel: boolean
  hotel_id: number | string | null
  hotel_name: string
  //address: string
  //floor: string
  //door_number: string
  zip: string
}

interface IProps {
  onLocationChange: (data: BookingLocation | null) => void
  locationNotFound: boolean
  onLocationNotFound: (notFound: boolean) => void
}

export const LocationFormSection = ({
  onLocationChange,
  locationNotFound,
  onLocationNotFound,
}: IProps) => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext<LocationFormSectionValues>()
  const {
    appState: { mobileView },
  } = useAppState()

  const { setValue, getValues, watch: formWatch } = useFormContext()

  const watchType = formWatch('type')

  //const watchCityType = watch('city_type')
  //const watchCityId = watch('city_id')

  const watchIsHotel = watch('is_hotel')
  const watchHotelId = watch('hotel_id')
  const watchZip = watch('zip')

  const isDisabled = false //!!location
  const isHotelDisabled = /*!!location ||*/ watchType === BOOKING_TYPE.ANIMATION /*|| !watchCityId*/

  const { loading, onFetch } = useLocationGeocode()

  const { mapLocationToGeocodeParams } = useBookingLocation()

  const zipItemBlur = () => {
    console.log('zipItemBlur:' + getValues('zip'))
    if (!isZipValid(getValues('zip')) && getValues('zip') != '____-___' && getValues('zip') != '') {
      onLocationChange(null)
      onLocationNotFound(true)
    } else {
      onGeocodeChange()
    }
  }

  const debouncedZipVerify = React.useCallback(debounce(zipItemBlur, 1000), [])

  const onSuccess = (data: any) => {
    console.log('success geolocate')
    if (data) {
      const geocode = data?.results[0]
      const address = geocode?.formatted_address
      const position = geocode.geometry?.location
      console.log('location:' + JSON.stringify({ address, position: { ...position } }))
      onLocationChange({ address, position: { ...position } })
    }
  }

  const onGeocodeChange = async () => {
    console.log('onGeocodeChange:' + getValues('zip') + ' watchHotelId:' + watchHotelId)
    const [
      //city_id,
      //city_type,
      //city_description,
      //country_id,
      //address,
      zip,
      is_hotel,
      hotel_id,
      hotel_name,
    ] = getValues([
      //'city_id',
      //'city_type',
      //'city_description',
      //'country_id',
      //'address',
      'zip',
      'is_hotel',
      'hotel_id',
      'hotel_name',
    ])

    if (isZipValid(zip)) {
      const geocodeParams = mapLocationToGeocodeParams({
        //city_id,
        //city_type,
        //city_description,
        //country_id,
        //address,
        zip,
        is_hotel,
        hotel_name,
      })

      await onFetch(geocodeParams, onSuccess, onLocationNotFound)
    }
  }

  const isZipValid = (zip: string) => {
    const re = new RegExp('^[0-9]{4}-[0-9]{3}$')
    return re.test(zip)
  }

  useEffect(() => {
    //console.log('use effect watchZip')
    debouncedZipVerify()
  }, [watchZip])

  useEffect(() => {
    //console.log('use effect watch hotel id')
    if (watchIsHotel) {
      //console.log('HOTEL ID: ' + watchHotelId)
      if (getValues('zip') != '____-___') {
        setValue('zip', '')
      }
      //console.log('location null')
      onLocationChange(null)
    }
    if (watchIsHotel && watchHotelId === HOTEL_TYPE.OTHER) {
      onGeocodeChange()
    }
  }, [watchHotelId])

  useEffect(() => {
    if (watchIsHotel) {
      //console.log('IS HOTEL: ' + watchIsHotel)
      setValue('zip', '')
      //console.log('location null')
      onLocationChange(null)
    }
  }, [watchIsHotel])

  return (
    <>
      {/*<ExtendedCityFormSection disabled={isDisabled} boldTitle={boldTitle} />

      {cityHelper.general.isCityExtraOption(watchCityType) && (
        <Alert type={'info'} mb={'md'}>
          {watchCityType === CITY_TYPE.OTHER
            ? t('booking.note.area.other')
            : watchCityType === CITY_TYPE.INTERNACIONAL
            ? t('booking.note.area.inter')
            : ''}
        </Alert>
      )}*/}

      {watchType !== BOOKING_TYPE.ANIMATION && <HotelFormSection disabled={isHotelDisabled} />}

      {/*!watchIsHotel && (
        <TextInputController
          control={control}
          name={'address'}
          id={'address'}
          size={mobileView ? 'md' : 'lg'}
          label={t('address')}
          placeholder={t('address')}
          mb={'md'}
          disabled={isDisabled}
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        />
      )*/}

      {(!watchIsHotel || watchHotelId == HOTEL_TYPE.OTHER) && (
        <>
          <Grid>
            <Grid.Col md={5}>
              <ZipInputController
                control={control}
                name={'zip'}
                id={'zip'}
                size={mobileView ? 'md' : 'lg'}
                label={t('postal_code')}
                placeholder={t('postal_code')}
                disabled={isDisabled}
                mb={'sm'}
                //onBlur={() => zipItemBlur()}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
          </Grid>

          {/*<Grid>
            <Grid.Col md={5}>
              <TextInputController
                control={control}
                name={'floor'}
                id={'floor'}
                size={mobileView ? 'md' : 'lg'}
                label={t('floor')}
                placeholder={t('floor')}
                mb={'md'}
                disabled={isDisabled}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col md={5}>
              <TextInputController
                control={control}
                name={'door_number'}
                id={'door_number'}
                size={mobileView ? 'md' : 'lg'}
                label={t('door_number')}
                placeholder={t('door_number')}
                mb={'md'}
                disabled={isDisabled}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
          </Grid>*/}

          {/*<ConfirmLocationFormSection
            location={location}
            onLocationChange={onLocationChange}
            locationNotFound={locationNotFound}
            onLocationNotFound={onLocationNotFound}
          />*/}

          {locationNotFound && (
            <Alert type="error" mb={'md'}>
              <Group className="gap-2">
                {t('location_not_found')}{' '}
                <ActionIcon onClick={onGeocodeChange}>
                  <Icon name="arrows-spin" size={14}></Icon>
                </ActionIcon>
              </Group>
            </Alert>
          )}
        </>
      )}
    </>
  )
}
