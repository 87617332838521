import { useAppState } from '@/features/app/hooks'
import { BookingInfoAlert, BookingNewBadge } from '@/features/bookings/components/Elements'
import { bookingHelper } from '@/features/bookings/helpers'
import { useBookingData } from '@/features/bookings/hooks'

const { isCanceledBabysitter, isCanceledAdmin, isCanceledLatePayment } = bookingHelper.status

export const Header = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { booking } = useBookingData()
  const { status, is_new } = booking ?? {}

  const canceledByBabysitter = isCanceledBabysitter(status)
  const canceledByAdmin = isCanceledAdmin(status)
  const canceledLatePayment = isCanceledLatePayment(status)

  const visible = booking && (booking?.has_unpaid_invoice || canceledByBabysitter || is_new)

  return (
    <>
      {visible && (
        <div className={mobileView ? 'flex flex-col p-3 gap-3' : 'flex flex-col mb-3 gap-3'}>
          {is_new && (
            <div>
              <BookingNewBadge />
            </div>
          )}

          {booking?.has_unpaid_invoice && <BookingInfoAlert type={'has_unpaid_invoice'} />}

          {canceledByBabysitter && <BookingInfoAlert type={'canceled_babysitter'} />}
          {canceledByAdmin && <BookingInfoAlert type={'canceled_admin'} />}
          {canceledLatePayment && <BookingInfoAlert type={'canceled_late_payment'} />}
        </div>
      )}
    </>
  )
}
