import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  wrap: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      backgroundColor: 'white',
      padding: '32px 32px',
      borderTopLeftRadius: '32px',
      borderTopRightRadius: '32px',
    },
  },
  imgWrap: {
    position: 'fixed',
    right: '-10px',
    bottom: '0',

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
      display: 'none',
    },
  },
  logosWrap: {
    position: 'absolute',
    bottom: '0',
    marginLeft: '-64px',
    marginBottom: '16px',
    cursor: 'pointer',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      marginLeft: '0px',
    },
  },
  infoBox: {
    position: 'relative',
    width: '335px',
    height: '304px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 16px',
    gap: '16px',
  },
  infoBoxHeader: {
    color: '#02334b',
    fontFamily: 'Circular Std Book !important',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center',
  },
  infoBoxText: {
    color: '#505a5f',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  imageLayout: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
  },
}))
