import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Input } from '@mantine/core'
import { TimeController } from '@/components/FormElements/Controllers/TimeInputSelectController/TimeController'
import { useAppState } from '@/features/app/hooks'
import { timetableHelper } from '@/features/booking/helpers'

const getWeekDay = timetableHelper.weekdays.getWeekDay

interface IProps {
  namePrefix: string
  weekDayKey: string
}

export const WeekDayTimePeriodFormSection = ({ namePrefix = '', weekDayKey }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { control, watch, setValue, getValues } = useFormContext()

  return (
    <>
      <Input.Label
        mb={8}
        styles={{
          label: {
            fontWeight: 'bold',
            fontSize: '16px !important',
          },
        }}
      >
        {getWeekDay(weekDayKey, t)?.label}
      </Input.Label>

      <Grid>
        <Grid.Col span={6}>
          <Grid.Col span={mobileView ? 6 : 4}>
            <TimeController
              control={control}
              name={`${namePrefix}.${weekDayKey}.time_start`}
              id={`${namePrefix}.${weekDayKey}.time_start`}
              label={t('from')}
              size={mobileView ? 'md' : 'lg'}
              mb={'md'}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          </Grid.Col>
        </Grid.Col>

        <Grid.Col span={6}>
          <Grid.Col span={mobileView ? 6 : 4}>
            <TimeController
              control={control}
              name={`${namePrefix}.${weekDayKey}.time_end`}
              id={`${namePrefix}.${weekDayKey}.time_end`}
              label={t('to')}
              size={mobileView ? 'md' : 'lg'}
              className={mobileView ? 'ml-2' : ''}
              mb={'md'}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          </Grid.Col>
        </Grid.Col>
      </Grid>
    </>
  )
}
