import { IconCirclePlus } from '@tabler/icons'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Group, Tooltip, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { FieldError } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { OnetimeRangesItem } from './OnetimeRangesItem'

export type OnetimeRangeFieldValues = {
  range_date_start: string | Date | null
  range_date_end: string | Date | null
  range_time_start: string | Date | null
  range_time_end: string | Date | null
}

interface IProps {
  name: string
  control: any
  limit?: number
  meta?: any
  clearErrors?: (name: any) => void
  gridProps?: any
  showTooltip: boolean
}

export const OnetimeRangesFieldArray = ({
  name,
  control,
  meta,
  clearErrors,
  gridProps,
  showTooltip,
}: IProps) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()

  const error = meta?.error

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const addRow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (clearErrors) {
      clearErrors(name)
    }

    const fields: OnetimeRangeFieldValues = {
      range_date_start: null,
      range_date_end: null,
      range_time_start: null,
      range_time_end: null,
    }

    append(fields)
  }

  const renderTooltip = () => {
    return (
      <div className="flex gap-1 font-bold">
        <span>{t('discount_tooltip.text')}</span>
      </div>
    )
  }

  const renderAdd = () => (
    <div className={'flex flex-col self-start mt-3'}>
      <Group>
        <Anchor href="#" onClick={addRow} className={'font-bold add-new-item add-language-color'}>
          <Group className={'gap-2'}>
            <IconCirclePlus size={18} />
            {t('add_dates')}
          </Group>
        </Anchor>
        {showTooltip && (
          <Tooltip
            label={renderTooltip()}
            position="top"
            withArrow
            multiline
            events={{ hover: true, touch: true, focus: false }}
          >
            <div>
              <Icon
                size={22}
                name={'discount'}
                color={theme.colors.primary[theme.fn.primaryShade()]}
              />
            </div>
          </Tooltip>
        )}
      </Group>
      <FieldError error={error} />
    </div>
  )

  return (
    <div className={'flex flex-col mb-3'}>
      {fields.map((item, index) => (
        <OnetimeRangesItem
          key={index}
          name={name}
          control={control}
          item={item}
          index={index}
          gridProps={gridProps}
          onRemove={remove}
        />
      ))}
      {renderAdd()}
    </div>
  )
}
