import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, useMantineTheme } from '@mantine/core'
import { Button, Icon } from '@/components/Elements'
import { chatsUsersAPI } from '@/features/chat/api'
import { chatConversationData } from '@/features/chat/store'
import { ChatModel, Participant } from '@/features/chat/types/models'

interface IProps {
  participants: Participant[]
  chat?: ChatModel
  showButton: boolean
}

export const ChatNavControl = ({ participants, chat, showButton }: IProps) => {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const navigateToChat = async (e: any) => {
    e.stopPropagation()

    dispatch(chatConversationData.initChat(chat))
    await chatsUsersAPI.seenUserMessages(chat?.id)
    dispatch(chatConversationData.initParticipants(participants))
    navigate(`/chat`)
  }

  return (
    <>
      {showButton ? (
        <Button onClick={navigateToChat}>{t('send_message')}</Button>
      ) : (
        <ActionIcon
          onClick={navigateToChat}
          component="div"
          size={'lg'}
          color={'gray'}
          radius="xl"
          variant={'light'}
        >
          <Icon name={'chat'} color={theme.black} />
        </ActionIcon>
      )}
    </>
  )
}
