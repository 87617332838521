import { IconArrowLeft } from '@tabler/icons'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Group, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useUser } from '@/features/user'
import useStyles from './BackLink.styles'

interface IProps {
  goToBookNow?: boolean
}

export const MobileBackLink = ({ goToBookNow = false }: IProps) => {
  const theme = useMantineTheme()

  const { classes } = useStyles()
  const { user, getBookingListEntryPath } = useUser()

  return (
    <div className={'flex'}>
      {user ? (
        <Link
          to={goToBookNow ? '/book-now' : getBookingListEntryPath()}
          className={classes.link}
          color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
        >
          <Group>
            {goToBookNow ? (
              <IconArrowLeft size={20} color={theme.colors.primary[theme.fn.primaryShade()]} />
            ) : (
              <Icon
                name={'calendar-search'}
                stroke={1}
                className={classes.linkIcon}
                color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
              />
            )}
          </Group>
        </Link>
      ) : (
        <Link
          to={/*goToBookNow ? '/book-now' :*/ '/'}
          className={classes.link}
          color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
        >
          <Group>
            <IconArrowLeft size={20} color={theme.colors.primary[theme.fn.primaryShade()]} />
          </Group>
        </Link>
      )}
    </div>
  )
}
