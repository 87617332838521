import moment from 'moment'
import * as Yup from 'yup'
import { HOTEL_TYPE } from '@/const/hotel'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { ValidationsUtils } from '@/utils'
import { validationHelper } from './helpers'

const generalStepValidationSchema = (frame: boolean) =>
  Yup.object().shape({
    /*city_id: Yup.mixed().nullable().required('field.error.required'),
    city_description: Yup.string().when('city_id', {
      is: (city_id: string) => cityHelper.general.isCityExtraOption(city_id),
      then: Yup.string().required('field.error.required'),
    }),
    country_id: Yup.mixed()
      .nullable()
      .when('city_id', {
        is: (city_id: string) => cityHelper.general.isInternational(city_id),
        then: Yup.mixed().nullable().required('field.error.required'),
      }),
    address: Yup.string()
      .nullable()
      .when(['is_hotel', 'type'], {
        is: (is_hotel: string, type: BOOKING_TYPE) => !is_hotel && type != BOOKING_TYPE.ONETIME,
        then: Yup.string()
          .nullable()
          .required('field.error.required')
          .notOneOf([Yup.ref('zip'), null], 'field.address.no_one_of_zip'),
      }),*/
    /*floor: Yup.string()
      .nullable()
      .when(['is_hotel', 'hotel_id'], {
        is: (is_hotel: string, hotel_id: string) => !is_hotel || hotel_id == HOTEL_TYPE.OTHER,
        then: Yup.string()
          .nullable()
          .required('field.error.required')
          .notOneOf([Yup.ref('zip'), null], 'field.address.no_one_of_zip'),
      }),*/
    zip: Yup.string()
      .nullable()
      .when(['is_hotel', 'hotel_id'], {
        is: (is_hotel: string, hotel_id: string) => !is_hotel || hotel_id == HOTEL_TYPE.OTHER,
        then: Yup.string()
          .nullable()
          .required('field.error.required')
          .test('zip', 'field.error.invalid', ValidationsUtils.zipFormat),
      }),
    hotel_id: Yup.string()
      .nullable()
      .when('is_hotel', {
        is: (is_hotel: string) => is_hotel,
        then: Yup.string().nullable().required('field.error.required'),
      }),
    hotel_name: Yup.string()
      .nullable()
      .when('hotel_id', {
        is: (hotel_id: string) => hotel_id == HOTEL_TYPE.OTHER,
        then: Yup.string().required('field.error.required'),
      }),
    date_start: Yup.date().nullable().required('field.error.required'),
    date_end: Yup.date()
      .nullable()
      .test({
        name: 'required',
        exclusive: false,
        params: {},
        message: 'field.error.required',
        test: function (value) {
          if (value || this.parent.type == BOOKING_TYPE.ONETIME) return true
          return false
        },
      })
      .test({
        name: 'dateEqualOrGrater',
        exclusive: false,
        params: {},
        message: 'field.error.date.after-or-equal',
        test: function (value) {
          if (this.parent.type == BOOKING_TYPE.ONETIME) return true
          if (value && this.parent.date_start) {
            return moment(value).diff(moment(this.parent.date_start)) >= 0
          }
          return ValidationsUtils.dateEqualOrAfter(value, this.parent.date_start)
        },
      }),
    /*hours_per_week: Yup.string()
      .nullable()
      .when(['type', 'living_in'], {
        is: (type: BOOKING_TYPE, living_in: boolean) =>
          type == BOOKING_TYPE.PERMANENT_NANNY && !living_in,
        then: Yup.string().nullable().required('field.error.required'),
      }),*/
    days_per_week: Yup.string()
      .nullable()
      .when(['type', 'living_in'], {
        is: (type: BOOKING_TYPE, living_in: boolean) =>
          type == BOOKING_TYPE.PERMANENT_NANNY && living_in,
        then: Yup.string().nullable().required('field.error.required'),
      }),
    email: Yup.string()
      .nullable()
      .when([], {
        is: () => frame,
        then: Yup.string().nullable().required('field.error.required'),
      }),
    name: Yup.string()
      .nullable()
      .when([], {
        is: () => frame,
        then: Yup.string().nullable().required('field.error.required'),
      }),
    primary_language_id: Yup.mixed().nullable().required('field.error.required'),
    timetable: Yup.object().shape({
      //...validationHelper.getTimePeriodValidations(),
      ...validationHelper.getDaysScheduleValidations(),
      ...validationHelper.getWeeksScheduleValidations(),
      ...validationHelper.getOnetimeRangeValidations(),
    }),
  })

const generalStepValidationSchemaNoLogin = () =>
  Yup.object().shape({
    first_name: Yup.string().nullable().required('field.error.required'),
    last_name: Yup.string().nullable().required('field.error.required'),
    email: Yup.string().nullable().required('field.error.required'),
  })

const careStepValidationSchema = (hasUser: boolean, isHotel: boolean, frame: boolean) => {
  return Yup.object().shape({
    address: Yup.string()
      .nullable()
      .when([], {
        is: () => !isHotel,
        then: Yup.string().nullable().required('field.error.required'),
      }),
    booking_children: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('field.error.required'),
          birthday: Yup.date().nullable().required('field.error.required'),
        })
      )
      .min(1, 'field.error.required')
      .max(5, 'field.error.children.max-count'),
    phone: Yup.string()
      .nullable()
      .required('field.error.required')
      .min(7, 'field.error.phone-invalid')
      .max(20, 'field.error.phone-invalid')
      .matches(/^[0-9]+$/, 'field.error.phone-invalid'),
    contact_email: Yup.string()
      .nullable()
      .when([], {
        is: () => !hasUser && !frame,
        then: Yup.string().nullable().required('field.error.required'),
      }),
  })
}

export const getValidationSchema = ({
  step,
  hasUser,
  isHotel,
  frame,
}: {
  step: number
  hasUser: boolean
  isHotel: boolean
  frame: boolean
}) => {
  if (step === 0) {
    return generalStepValidationSchema(frame)
  } else if (step === 2) {
    return careStepValidationSchema(hasUser, isHotel, frame)
  } else if (step == 1) {
    return generalStepValidationSchemaNoLogin()
  } else {
    return Yup.object().shape({})
  }
}
