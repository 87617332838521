import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import {
  AboutChildrenBlock,
  ChildrenBlock,
  LanguagesBlock,
  LocationBlock,
  OtherInfoBlock,
} from '@/features/client/components/Profile/InfoBlocks'
import { ClientProfile, useUser } from '@/features/user'
import { ProfileDetailsLayout } from '../ProfileDetailsLayout'
import { General } from './General'

export const ClientProfileDetails = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const navigate = useNavigate()

  const {
    appState: { mobileView },
  } = useAppState()

  const { client_profile } = user

  const clientProfile = client_profile || ({} as ClientProfile)

  const { about_child, other_info, children, languages } = clientProfile
  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigate('/logout')
  }

  return (
    <>
      <ProfileDetailsLayout>
        {client_profile && (
          <>
            <General />
          </>
        )}

        {client_profile && (
          <div className={'my-3'}>
            <LocationBlock data={clientProfile} />
          </div>
        )}

        <div className={'mt-3'}>
          <ChildrenBlock data={children} />
        </div>

        <div className={'mt-3'}>
          <LanguagesBlock data={languages} />
        </div>

        <div className={'my-3'}>
          <AboutChildrenBlock data={about_child} />
        </div>

        <div className={'mt-3'}>
          <OtherInfoBlock data={other_info} />
        </div>

        {mobileView && (
          <div className={'w-100'}>
            <Button
              mt={'md'}
              fullWidth
              variant={'outline'}
              size={'md'}
              onClick={onClick}
              styles={(theme) => ({
                root: {
                  fontSize: '14px',
                },
              })}
            >
              {t('logout')}
            </Button>
          </div>
        )}
      </ProfileDetailsLayout>
    </>
  )
}
