import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { LoadingScreen } from '@/components/Elements'
import { MobileHeader } from '@/components/Elements/Header/MobileHeader'
import { BottomTabsBookNow } from '@/components/Elements/Tabs/BottomTabs/BottomTabsBookNow'
import { useAppState } from '@/features/app/hooks'
import { panelGeneral, panelLoader } from '@/features/panel/store'
import { useAppSelector } from '@/store'
import useStyles from './BasicLayout.styles'
import { Header } from './Header'

interface IProps {
  children?: any
  header?: boolean
  lang?: boolean
  loadSelects?: boolean
  bookNow?: boolean
  useOpacity?: boolean
  siteRedirect?: boolean
}

export const BasicLayout = ({
  children,
  header = true,
  lang = false,
  bookNow = false,
  useOpacity = false,
  loadSelects,
  siteRedirect = false,
}: IProps) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [loadedInitialStarted, setLoadedInitialStarted] = React.useState(false)
  const { loading, error, prepared } = useAppSelector((state) => state.panel.loader)

  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    if (loadSelects) {
      dispatch(panelLoader.loadData())

      return () => {
        dispatch(panelGeneral.resetState())
      }
    } else {
      setLoadedInitialStarted(true)
    }
    console.log('BASIC' + siteRedirect)
  }, [])

  useEffect(() => {
    if (loading) setLoadedInitialStarted(true)
  }, [loading])

  return (!loadedInitialStarted || loading) && loadSelects ? (
    <LoadingScreen />
  ) : (
    <div className={classes.wrapper}>
      {header && !mobileView && (
        <Header lang={lang} bookNow={bookNow} siteRedirect={siteRedirect} />
      )}
      {header && mobileView && (
        <>
          <MobileHeader
            border={false}
            showBackLink={bookNow}
            useOpacity={useOpacity}
            siteRedirect={siteRedirect}
          />
        </>
      )}
      <div className={classes.content}>{children ? children : <Outlet />}</div>
      {bookNow && (
        <div className="sticky-footer">
          <BottomTabsBookNow />
        </div>
      )}
    </div>
  )
}
