import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingInfoAlert } from '@/features/bookings/components/Elements'
import { bookingHelper } from '@/features/bookings/helpers'
import { BookingModel } from '@/features/bookings/types/models'
import useStyles from './AlertInfoBlock.styles'

const { isCanceledBabysitter, isCanceledAdmin, isCanceledLatePayment } = bookingHelper.status

interface IProps {
  data: BookingModel
}

export const AlertInfoBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { has_unpaid_invoice, status } = data

  return (
    <>
      {(has_unpaid_invoice || isCanceledBabysitter(status)) && (
        <div className={classes.root}>
          {has_unpaid_invoice && <BookingInfoAlert type={'has_unpaid_invoice'} />}

          {isCanceledBabysitter(status) && <BookingInfoAlert type={'canceled_babysitter'} />}
          {isCanceledAdmin(status) && <BookingInfoAlert type={'canceled_admin'} />}
          {isCanceledLatePayment(status) && <BookingInfoAlert type={'canceled_late_payment'} />}
        </div>
      )}
    </>
  )
}
