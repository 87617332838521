import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { ContentCard, MegaTagTitle } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingDetailsContainer } from '@/features/bookings/components/Booking/BookingDrawerManager/BookingDetails'
import { BookingDrawer } from '@/features/bookings/components/Booking/BookingDrawerManager/BookingDrawer'
import { useBookingDrawerManagerContext } from '@/features/bookings/contexts'
import { useUser } from '@/features/user/hooks/useUser'

export const Booking = () => {
  const {
    appState: { mobileView },
  } = useAppState()
  const { getBookingListEntryPath } = useUser()
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    navigation: { onScreenDataClean },
  } = useBookingDrawerManagerContext()

  const close = () => {
    navigate(getBookingListEntryPath())
  }
  return (
    <>
      <MegaTagTitle title={'booking'} />

      {!mobileView ? (
        <ContentCard title={t('booking_details')}>
          {id && (
            <BookingDetailsContainer id={id} onDestroy={onScreenDataClean} onDrawerClose={close} />
          )}
        </ContentCard>
      ) : (
        <BookingDrawer title={t('booking_details')} opened={true} onClose={close}>
          <BookingDetailsContainer
            id={id || '0'}
            onDestroy={onScreenDataClean}
            onDrawerClose={close}
          />
        </BookingDrawer>
      )}
    </>
  )
}
