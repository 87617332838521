import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Group, Text, useMantineTheme } from '@mantine/core'
import { Button, Icon, Modal } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { usePopup } from '@/hooks'
import useStyles from './LogoutLink.styles'

export const LogoutLink = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    appState: { mobileView },
  } = useAppState()

  const { visible, open, close } = usePopup()
  const { classes, cx } = useStyles()

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigate('/logout')
  }

  return (
    <div className={'flex'}>
      {mobileView ? (
        <Icon
          name={'logout'}
          onClick={open}
          className={classes.linkIcon}
          color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
        />
      ) : (
        <Link to={'/'} onClick={onClick} className={classes.link}>
          <Group>
            <Icon name={'logout'} className={classes.linkIcon} color={theme.black} />
            <Text className={classes.text}>{t('complete_later')}</Text>
          </Group>
        </Link>
      )}
      {mobileView && (
        <Modal opened={visible} onClose={close} centered title={`${t('logout')}?`}>
          <div className={'flex flex-col items-center'}>
            <div className={'w-full'}>
              <div className={'flex w-100 justify-center'}>
                <Text>{t('logout_confirm')}</Text>
              </div>
              <div className={'flex w-100 pointer'}>
                <Button
                  mt={'md'}
                  fullWidth
                  color={'primary'}
                  size={'md'}
                  onClick={onClick}
                  styles={(theme) => ({
                    root: {
                      fontSize: '14px',
                    },
                  })}
                >
                  {t('yes_logout')}
                </Button>
              </div>
              <div className={'flex w-100 pointer'}>
                <Button
                  mt={'md'}
                  fullWidth
                  variant="outline"
                  color="white"
                  size={'md'}
                  onClick={close}
                  styles={(theme) => ({
                    root: {
                      fontSize: '14px',
                      borderColor: 'white',
                      color: 'black',
                    },
                  })}
                >
                  {t('no_logout')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
