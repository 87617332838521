import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
//import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { BookingTimetableFormConnector } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingTimetableFormConnector'
import { LanguagesFormSection } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/CareDetailsForm/FormSections'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
//import { useBookingPeriod } from '../../hooks'
import { BookingLocation } from '../../types'
import { BookingPeriodFormSection } from './BookingPeriodFormSection'
import { DiscountFormSection } from './DiscountCodeSection'
import { LocationFormSection } from './LocationFormSection/LocationFormSection'
import { PermanentNannyFormSection } from './PermanentNannyFormSection/PermanentNannyFormSection'
import { TimetableSection } from './TimetableSection'
import { TypeFormSection } from './TypeFormSection/TypeFormSection'

interface IProps {
  location: BookingLocation | null
  locationNotFound: boolean
  onLocationNotFound: (notFound: boolean) => void
  onLocationChange: (data: BookingLocation | null) => void
  frame: boolean
}

export const DataForm = ({
  location,
  onLocationChange,
  locationNotFound,
  onLocationNotFound,
  frame,
}: IProps) => {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()
  const navigate = useNavigate()
  const watchType = watch('type')
  const watchLivingIn = watch('living_in')
  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    if (watchType === BOOKING_TYPE.REGULAR) {
      setValue('timetable.same_weeks_schedule', true)
      setValue('timetable.same_days_time', true)
      if (!frame) navigate('/booking/regular/new')
    } else if (watchType === BOOKING_TYPE.ONETIME) {
      setValue('timetable.same_weeks_schedule', false)
      setValue('timetable.same_days_time', false)
      if (!frame) navigate('/booking/onetime/new')
    } else if (watchType === BOOKING_TYPE.PERMANENT_NANNY) {
      setValue('timetable.same_weeks_schedule', !watchLivingIn)
      setValue('timetable.same_days_time', !watchLivingIn)
    }

    if (watchType != BOOKING_TYPE.PERMANENT_NANNY) {
      setValue('days_per_week', null)
      setValue('living_in', false)
    }
    if (watchType == BOOKING_TYPE.ANIMATION) {
      setValue('is_hotel', false)
    }
    if (watchType != BOOKING_TYPE.ONETIME) {
      setValue('timetable.onetime_ranges', [])
    }
    if (watchType != BOOKING_TYPE.REGULAR) {
      setValue('timetable.exception_dates', [])
    }
  }, [watchType])

  useEffect(() => {
    if (watchType == BOOKING_TYPE.PERMANENT_NANNY) {
      setValue('timetable.same_days_time', !watchLivingIn)
      setValue('timetable.same_weeks_schedule', !watchLivingIn)
      setValue('days_per_week', null)
      setValue('timetable.weeks_schedule', [])
    }
  }, [watchLivingIn])

  return (
    <>
      <BookingTimetableFormConnector />

      {frame && (
        <>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            size={mobileView ? 'md' : 'lg'}
            label={t('email')}
            placeholder={t('email')}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
          <TextInputController
            control={control}
            name={'name'}
            id={'name'}
            size={mobileView ? 'md' : 'lg'}
            label={t('first_name')}
            placeholder={t('first_name')}
            mt={'md'}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
          <TypeFormSection frame={frame} />
        </>
      )}
      {(watchType == BOOKING_TYPE.ONETIME || watchType == BOOKING_TYPE.REGULAR) && (
        <LocationFormSection
          onLocationChange={onLocationChange}
          locationNotFound={locationNotFound}
          onLocationNotFound={onLocationNotFound}
        />
      )}

      {!frame && (watchType == BOOKING_TYPE.ONETIME || watchType == BOOKING_TYPE.REGULAR) && (
        <TypeFormSection frame={frame} />
      )}
      {frame && (watchType == BOOKING_TYPE.ONETIME || watchType == BOOKING_TYPE.REGULAR) && (
        <Space h={'md'} />
      )}
      <BookingPeriodFormSection />

      {watchType === BOOKING_TYPE.PERMANENT_NANNY && <PermanentNannyFormSection />}

      <TimetableSection />

      {/*valid && watchType === BOOKING_TYPE.LIVE_IN && <TimetableSectionWeek />*/}

      {/*<InfoFormSection />*/}

      {watchType != BOOKING_TYPE.ONETIME && watchType != BOOKING_TYPE.REGULAR && (
        <LocationFormSection
          onLocationChange={onLocationChange}
          locationNotFound={locationNotFound}
          onLocationNotFound={onLocationNotFound}
        />
      )}

      <LanguagesFormSection boldTitle={true} />

      {(watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR) && (
        <>
          {mobileView && <Space h={'xs'} />}
          <DiscountFormSection />
        </>
      )}
      {!frame && <Space h={'xl'} />}
      {!frame && mobileView && <Space h={'xl'} />}
    </>
  )
}
