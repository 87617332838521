import React from 'react'
import { NumericFormat } from 'react-number-format'
import { currencyHelper } from '@/helpers'

const currencySymbol = currencyHelper.general.getGeneralCurrency()?.symbol

interface IProps {
  value: string | number
}

export const CurrencyFormat = ({ value = '', ...props }: IProps) => {
  return (
    <NumericFormat
      value={value}
      prefix={`${currencySymbol} `}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale={true}
      {...props}
    />
  )
}
