import React from 'react'
import { Logo } from './Logo'

interface IProps {
  type?: string
  bookinLogo?: boolean
  siteRedirect?: boolean

  [x: string]: any
}

export const LogoIcon = ({ type, bookingLogo = false, siteRedirect, ...props }: IProps) => {
  switch (type) {
    default:
      return <Logo bookingLogo={bookingLogo} siteRedirect={siteRedirect} {...props} />
  }
}
