import Plausible from 'plausible-tracker'
import { useEffect, useLayoutEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useAppWindowFocus, useEvents, useMixPanel } from '@/features/app/hooks'
import { appState } from '@/features/app/store'
import { RootNavigator } from '@/routes'

export const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER || '',
  }
  TagManager.initialize(tagManagerArgs)
}

export const initializePlausible = () => {
  if (process.env.REACT_APP_PLAUSIBLE_SEND === 'true') {
    const plausible = Plausible({
      domain: 'babysisters.pt',
    })
    plausible.enableAutoPageviews()
    console.log('plausible initialized')
  }
}

export const initializeZendesk = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.zE('messenger', 'hide')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.zE('messenger:on', 'close', function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger', 'hide')
  })
}

function App() {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const theme = useMantineTheme()
  const isSmallerThenMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)
  const event = useEvents()
  const isFrame = window.self !== window.top

  useAppWindowFocus()
  useMixPanel()
  initializeZendesk()

  useEffect(() => {
    if (!isFrame) {
      initializeTagManager()
      initializePlausible()
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger:set', 'locale', i18n.language)
  }, [])

  useLayoutEffect(() => {
    if (!isFrame) event.actions.appLoaded({})
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger:set', 'locale', i18n.language)
  }, [i18n.language])

  useEffect(() => {
    dispatch(appState.mobileView(isSmallerThenMd))
  }, [isSmallerThenMd])

  useEffect(() => {
    dispatch(appState.frameView(isFrame))
  }, [isFrame])

  return <RootNavigator />
}

export default App
