import Plausible from 'plausible-tracker'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { LoginModal } from '@/components/Elements/Modals/LoginModal/LoginModal'
import { useEvents, useMixPanel } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { BookingConfirmedContainer, Layout } from '@/features/booking/components/BookingConfirmed'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'
import { ageMean } from '../../components/Booking/BookingContainer/BookingWizardForm/helpers'

interface IProps {
  frame?: boolean
}

export const Confirmed = ({ frame = false }: IProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const events = useEvents()
  const { user, getBookingListEntryPath } = useUser()
  const { isLoggedIn } = useAuth()
  const mixPanel = useMixPanel()
  const { trackEvent } = Plausible({
    domain: 'babysisters.pt',
  })
  const [justCreatedUser, setJustCreatedUser] = useState(false)
  const [paymentConfirmed, setPaymentConfirmed] = useState(false)
  const [visibleLoginModal, setVisibleLoginModal] = useState(false)
  const [bookingId, setBookingId] = useState(null)

  useEffect(() => {
    if (!booking) {
      navigate(getBookingListEntryPath())
    } else {
      if (!frame) {
        setPaymentConfirmed(booking.payment_confirmed)
        const type = booking.type
        const total = (booking.total_amount / 100).toFixed(2)
        const bookingId = booking.id

        trackEvent(
          type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.REGULAR
            ? 'booking/confirmed'
            : type + '/confirmed'
        )
        if (isLoggedIn) {
          try {
            events.actions.careDetails({
              Type: type,
              Value: total,
              Booking: bookingId,
              AgeMean: ageMean(booking.booking_children),
            })
          } catch (e) {
            console.error('Error sending careDetails event')
            console.error(e)
          }
        } else {
          //if (booking?.payment_confirmed) {
          const purchaseType =
            type === BOOKING_TYPE.ONETIME
              ? TAG_MANAGER_EVENT.PURCHASE_OCASIONAL
              : TAG_MANAGER_EVENT.PURCHASE_REGULAR

          const userHasntBookings = user && !user.has_bookings
          try {
            events.actions.purchaseType(
              purchaseType,
              total,
              bookingId,
              userHasntBookings,
              'care_details_first',
              booking.created_admin || false
            )
          } catch (e) {
            console.error('Error sending careDetails event')
            console.error(e)
          }
          try {
            events.actions.purchaseEcommerce(total, {
              transaction_id: bookingId,
              coupon: purchaseType,
              affiliation: purchaseType,
              total: total,
              value: total,
              currency: 'EUR',
              items: [
                {
                  item_name: purchaseType,
                },
              ],
            })
          } catch (e) {
            console.error('Error sending careDetails event')
            console.error(e)
          }
        }
        //}
        setJustCreatedUser(booking.just_created_user)
      } else {
        setBookingId(booking.id)
        if (booking.isNewuser) {
          try {
            mixPanel.actions.reset()
            mixPanel.actions.identify(booking.user.id)
            mixPanel.actions.track(TAG_MANAGER_EVENT.REGISTER, {
              Type: 'Admin',
            })
            mixPanel.actions.track(TAG_MANAGER_EVENT.BOOKING_ADMIN, {
              Type: booking.type,
              Booking: booking.id,
            })
            mixPanel.actions.reset()
          } catch (e) {
            console.error('Error sending register event')
            console.error(e)
          }
        } else {
          mixPanel.actions.reset()
          mixPanel.actions.identify(booking.user.id)
          mixPanel.actions.track(TAG_MANAGER_EVENT.BOOKING_ADMIN, {
            Type: booking.type,
            Booking: booking.id,
          })
          mixPanel.actions.reset()
        }
      }
    }
    if (!frame) {
      if (isLoggedIn) {
        dispatch(checkoutState.setBooking(null))
      } else {
        setVisibleLoginModal(!booking.created_admin)
        localStorage.setItem('confirmed-booking-without-user', booking.id)
        dispatch(checkoutState.setBooking(null))
      }
    }
  }, [])

  const onClose = () => {
    setVisibleLoginModal(false)
  }

  const onGuest = async () => {
    localStorage.removeItem('confirmed-booking-without-user')
    setVisibleLoginModal(false)
  }

  return (
    <>
      <MegaTagTitle title={'booking_confirmed'} />

      <Layout>
        <BookingConfirmedContainer
          justCreatedUser={justCreatedUser}
          paymentConfirmed={paymentConfirmed}
          bookingId={bookingId}
          frame={frame}
        />
      </Layout>
      <LoginModal visible={visibleLoginModal} onLoginModalClose={onClose} onGuestClose={onGuest} />
    </>
  )
}
