import * as Yup from 'yup'

const passwordMinLength = 8

export const validationSchema = Yup.object().shape({
  current_password: Yup.string()
    .required('field.error.required')
    .min(passwordMinLength, 'field.error.password.length'),
  password: Yup.string()
    .required('field.error.required')
    .min(passwordMinLength, 'field.error.password.length'),
  //password_confirmation: Yup.string()
  //  .required('field.error.required')
  //  .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
  //  .min(passwordMinLength, 'field.error.password.length'),
})
