import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Center, Text, Tooltip, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { SegmentedControlController, SelectController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingOptions } from '@/features/bookings/hooks'

interface IProps {
  frame: boolean
}

export const TypeFormSection = ({ frame }: IProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { control } = useFormContext()

  const { getType } = useBookingOptions()
  const {
    appState: { mobileView },
  } = useAppState()

  const renderTooltip = () => {
    return (
      <div className="flex gap-1 font-bold">
        <span>{t('discount_regular_tooltip.text')}</span>
      </div>
    )
  }

  const options = [
    {
      label: (
        <Center className="gap-2">
          <Icon size={mobileView ? 16 : 20} name={getType(BOOKING_TYPE.ONETIME)?.iconName} />
          <Text size={mobileView ? 16 : 20}>{getType(BOOKING_TYPE.ONETIME)?.label}</Text>
        </Center>
      ),
      value: BOOKING_TYPE.ONETIME,
    },
    {
      label: (
        <Center className="gap-2">
          <Icon size={mobileView ? 16 : 20} name={getType(BOOKING_TYPE.REGULAR)?.iconName} />
          <Text size={mobileView ? 16 : 20}>{getType(BOOKING_TYPE.REGULAR)?.label}</Text>
          <Tooltip
            label={renderTooltip()}
            position="top"
            withArrow
            multiline
            events={{ hover: true, touch: true, focus: false }}
          >
            <div>
              <Icon
                size={22}
                name={'discount'}
                color={theme.colors.primary[theme.fn.primaryShade()]}
              />
            </div>
          </Tooltip>
        </Center>
      ),
      value: BOOKING_TYPE.REGULAR,
    },
  ]

  const optionsFrame = [
    {
      label: getType(BOOKING_TYPE.ONETIME)?.label,
      value: BOOKING_TYPE.ONETIME,
    },
    {
      label: getType(BOOKING_TYPE.REGULAR)?.label,
      value: BOOKING_TYPE.REGULAR,
    },
    {
      label: getType(BOOKING_TYPE.PERMANENT_NANNY)?.label,
      value: BOOKING_TYPE.PERMANENT_NANNY,
    },
    {
      label: getType(BOOKING_TYPE.ANIMATION)?.label,
      value: BOOKING_TYPE.ANIMATION,
    },
  ]

  return (
    <div className="flex flex-col">
      {!frame && (
        <>
          <label className="mantine-InputWrapper-label mantine-Textarea-label phone-widget-title mt-2">
            {t('kind_of_care')}
          </label>
          <SegmentedControlController
            label={t('kind_of_care')}
            data={options}
            control={control}
            name={'type'}
            mb={'md'}
            innerTitle={false}
          />
        </>
      )}

      {frame && (
        <SelectController
          control={control}
          name={`type`}
          label={t('kind_of_care')}
          mb={'md'}
          mt={'md'}
          size={mobileView ? 'md' : 'lg'}
          data={optionsFrame}
          withinPortal
          styles={
            mobileView
              ? {
                  label: {
                    fontWeight: 'bold',
                    fontSize: '16px !important',
                  },
                }
              : {}
          }
        />
      )}
    </div>
  )
}
