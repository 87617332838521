import { getAuthRoutes } from '@/routes/configs/auth'
import { getPrivateRoutes } from '@/routes/configs/private'
import { getPublicRoutes } from '../public/public-routes-config'

export const getRootRoutes = (isLoggedIn: boolean, user: object | null): any => {
  return [
    //...getBookNewRoutes(isLoggedIn, user),
    ...getPublicRoutes(isLoggedIn),
    //...getPrivateBookingRoutes(isLoggedIn, user),
    ...getPrivateRoutes(isLoggedIn, user), // order is necessary (for index route go to private route)
    ...getAuthRoutes(isLoggedIn),
  ]
}
