import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  section: {
    height: '100%',
    display: 'flex',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
    },
  },

  infoSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '40%',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
      padding: '0px',
      marginBottom: '12px',
    },
  },

  contentSection: {
    width: '60%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '36px',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
    },
  },
  contentSectionFrame: {
    width: '60%',
    height: '100%',
    backgroundColor: 'white',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '100%',
    },
  },

  stepperWap: {
    flex: 1,
    padding: '40px 0',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '0',
      paddingTop: '8px',
    },
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  cardBody: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },

  scrollArea: {
    width: '100%',
  },
}))
