import { useFormContext } from 'react-hook-form'
import { Space } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { OnetimeRangesFieldArray } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/GeneralForm/OnetimeRangesFieldArray'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { BookingTimetableDateTimeType } from '@/features/bookings/types/models'
import {
  ConfigFormSection,
  DaysScheduleFormSection,
  ExceptionDatesFormSection,
  TimePeriodFormSection,
  WeeksScheduleFormSection,
} from './FormSections'

export type OnetimeRangesFormSectionValues = {
  onetime_ranges: Array<BookingTimetableDateTimeType>
  timetable: {
    onetime_ranges: Array<BookingTimetableDateTimeType>
  }
}

export const TimetableSection = () => {
  const { watch } = useFormContext()
  const watchType = watch('type')
  const watchLivingIn = watch('living_in')
  const watchSameWeeks = watch('timetable.same_weeks_schedule')
  const watchSameDaysTime = watch('timetable.same_days_time')
  const {
    appState: { mobileView },
  } = useAppState()

  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<OnetimeRangesFormSectionValues>()
  return (
    <>
      {watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.ANIMATION ? (
        <OnetimeRangesFieldArray
          name={'timetable.onetime_ranges'}
          control={control}
          clearErrors={clearErrors}
          showTooltip={watchType === BOOKING_TYPE.ONETIME}
          meta={{ error: errors?.timetable?.onetime_ranges }}
        />
      ) : (
        <div>
          {watchType !== BOOKING_TYPE.PERMANENT_NANNY && <ExceptionDatesFormSection />}
          {watchType === BOOKING_TYPE.REGULAR && <Space h={'xs'} />}

          {watchType !== BOOKING_TYPE.REGULAR && watchType !== BOOKING_TYPE.PERMANENT_NANNY && (
            <ConfigFormSection />
          )}

          {watchDateStart &&
            watchDateEnd &&
            watchSameWeeks &&
            (watchType !== BOOKING_TYPE.PERMANENT_NANNY || !watchLivingIn) && (
              <DaysScheduleFormSection />
            )}

          {watchDateStart &&
            watchDateEnd &&
            watchSameDaysTime &&
            (watchType !== BOOKING_TYPE.PERMANENT_NANNY || !watchLivingIn) && (
              <TimePeriodFormSection />
            )}

          {!watchSameWeeks && watchType !== BOOKING_TYPE.PERMANENT_NANNY && (
            <WeeksScheduleFormSection />
          )}
        </div>
      )}
    </>
  )
}
