import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController } from '@/components/FormElements'
import { FormTypes } from '@/types'
import { ValidationsUtils } from '@/utils'

export type FormValues = {
  password: string
  //password_confirmation: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

const passwordMinLength = 8

export const ResetPasswordForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      password: '',
      //password_confirmation: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .required('field.error.required')
          .min(passwordMinLength, 'field.error.password.length'),
        //password_confirmation: Yup.string()
        //  .required('field.error.required')
        //  .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
        //  .min(passwordMinLength, 'field.error.password.length'),
      })
    ),
  })

  const [alertError, setAlertError] = useState<string | null>(null)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setAlertError(null)
    try {
      await props.onSubmit(data)
    } catch (err) {
      const serverError = err as FormTypes.ValidationErrors
      setAlertError(serverError?.message || t('error'))
      ValidationsUtils.setServerSideErrors(serverError?.errors, setError)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {alertError && (
          <Alert type={'error'} mb={'sm'}>
            {alertError}
          </Alert>
        )}

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: passwordMinLength }}
        />

        {/*<PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: passwordMinLength }}
        />*/}

        <Space h={'md'} />

        <Button fullWidth type={'submit'} disabled={isSubmitting} loading={isSubmitting}>
          {t('reset')}
        </Button>
      </form>
    </>
  )
}
