import { IconCirclePlus } from '@tabler/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Anchor, Group } from '@mantine/core'
import { Modal } from '@/components/Elements'
import { useNotify, usePopup } from '@/hooks'
import { SelectDatesForm } from './SelectDatesForm'

interface IProps {
  value: Date[]
  onChange: (dates: Date[]) => void
  period: Date[]
}

export const SelectDates = ({ value, onChange, period }: IProps) => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const { visible, open, close } = usePopup()

  const onApply = (dates: Date[]) => {
    onChange(dates)
    close()
  }

  const openSelect = () => {
    if (period + '' != ',') open(true)
    else
      showNotification({
        type: 'error',
        message: t('add_exception_dates_error'),
      })
  }

  return (
    <>
      <div className={'flex self-start'}>
        <Anchor
          href="#"
          onClick={openSelect}
          className={'font-bold add-new-item add-language-color'}
        >
          <Group className={'gap-2 mt-3'}>
            <IconCirclePlus size={18} />
            {t('add_exception_dates')}
          </Group>
        </Anchor>

        <Modal opened={visible} onClose={close} centered title={t('select_exception_dates')}>
          <SelectDatesForm
            onApply={onApply}
            period={period}
            initialValues={{ dates: value }}
            onCancel={close}
          />
        </Modal>
      </div>
    </>
  )
}
