import { useEffect } from 'react'
import { useLogout } from '@/features/auth'

export const Logout = () => {
  const { logout } = useLogout()

  useEffect(() => {
    logout()
  }, [])

  return <></>
}
