import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { Center, Group, Text, Title } from '@mantine/core'
import { Modal as MantineModal } from '@mantine/core'
import { Alert, AppLangPicker, Button, LogoIcon, Modal } from '@/components/Elements'
import { AccountNotApprovedInfo } from '@/features/auth/components/Elements'
import { ForgotPasswordContainer } from '@/features/auth/components/ForgotPassword'
import { LoginContainer } from '@/features/auth/components/Loging'
import { RegisterContainer } from '@/features/auth/components/Register'
import { ROLE } from '@/features/user'
import { usePopup } from '@/hooks'
import useStyles from './LoginModal.styles'

interface IProps {
  visible: boolean
  onLoginModalClose: () => void
  onGuestClose: () => void
}

interface IAlert {
  type: string
  message: string
}

export const LoginModal = ({ visible, onLoginModalClose, onGuestClose, ...props }: IProps) => {
  const [type, setType] = useState('auth')
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [alert, setAlert] = useState<IAlert | null>(null)

  const { visible: popupVisible, open, close } = usePopup()

  const setAlertMessage = (data: IAlert | null = null) => {
    setAlert(data)
  }

  const onApprovedInfo = () => {
    open({})
  }

  const goToAuth = () => {
    setType('auth')
  }
  const goToLogin = () => {
    setType('login')
  }
  const goToRecover = () => {
    setType('recover')
  }

  const renderAuth = () => (
    <>
      <Group>
        <Center className="w-100">
          <Title order={2} className={'text-center mb-2'}>
            {ReactHtmlParser(t('login_modal.message'))}
          </Title>
        </Center>

        <div className="w-full gap-4 flex flex-col justify-center items-center">
          <div className="w-full flex gap-4">
            <Button fullWidth color={'secondary'} radius={32} onClick={() => setType('login')}>
              {t('login')}
            </Button>

            <Button fullWidth radius={32} onClick={() => setType('register')}>
              {t('register')}
            </Button>
          </div>
        </div>
      </Group>

      <LoginContainer
        hideForm
        role={ROLE.Client}
        onApprovedInfo={onApprovedInfo}
        onLoginModalClose={onLoginModalClose}
        onGuestClose={onGuestClose}
        fromPopup={true}
      />
    </>
  )

  return (
    <>
      <MantineModal onClose={onLoginModalClose} opened={visible} withCloseButton={false} centered>
        <div className={classes.wrap}>
          <div className={'w-100 d-flex justify-between'}>
            <LogoIcon size={'sm'} />

            <AppLangPicker />
          </div>
          {alert?.message && (
            <div className={'mt-2'}>
              <Alert type={'success'} mb={'md'}>
                {t(alert.message)}
              </Alert>
            </div>
          )}

          <div className={'mt-4'}>
            {type === 'auth' && renderAuth()}
            {type === 'login' && (
              <LoginContainer
                fromPopup={true}
                role={ROLE.Client}
                onGoBack={goToAuth}
                onApprovedInfo={onApprovedInfo}
                onGoRecover={goToRecover}
                onLoginModalClose={onLoginModalClose}
              />
            )}
            {type === 'register' && (
              <RegisterContainer
                role={ROLE.Client}
                fromPopup={true}
                onGoBack={goToAuth}
                onLoginModalClose={onLoginModalClose}
              />
            )}
            {type === 'recover' && (
              <ForgotPasswordContainer fromPopup={true} onGoBack={goToLogin} />
            )}
          </div>
          <AccountNotApprovedInfo visible={popupVisible} onClose={close} />
        </div>
      </MantineModal>
    </>
  )
}
