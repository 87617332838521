import React from 'react'
import { Grid } from '@mantine/core'
import { BookingRequest } from '@/features/bookings/types/models'
import { BookingRequestSection } from '../../BookingRequestSection'

interface IProps {
  items: BookingRequest[]
  onView: (id: number) => void
  onBabysitterFavoriteSuccess: (data: any) => void
  onCandidateHireSuccess: (bookingId: number, candidate: object) => void
}

export const GridView: React.FC<IProps> = ({ items, ...props }) => {
  return (
    <>
      <Grid grow>
        {items.map((item, i) => (
          <Grid.Col key={item.id}>
            <BookingRequestSection data={item} {...props} />
          </Grid.Col>
        ))}
      </Grid>
    </>
  )
}
