import _ from 'lodash'
import { BOOKING_STATUS } from '@/features/bookings/consts/booking'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: BOOKING_STATUS
  color: string
}

export const getStatuses = () => {
  return [
    {
      label: i18n.t('unpaid'),
      value: BOOKING_STATUS.UNPAID,
      color: 'yellow',
    },
    {
      label: i18n.t('waiting_payment'),
      value: BOOKING_STATUS.WAITING_PAYMENT,
      color: 'yellow',
    },
    {
      label: i18n.t('canceled_admin'),
      value: BOOKING_STATUS.CANCELED_ADMIN,
      color: 'blue',
    },
    {
      label: i18n.t('canceled_client'),
      value: BOOKING_STATUS.CANCELED_CLIENT,
      color: 'blue',
    },
    {
      label: i18n.t('canceled_babysitter'),
      value: BOOKING_STATUS.CANCELED_BABYSITTER,
      color: 'blue',
    },
    {
      label: i18n.t('canceled_late_payment'),
      value: BOOKING_STATUS.CANCELED_LATE_PAYMENT,
      color: 'blue',
    },
    {
      label: i18n.t('finished'),
      value: BOOKING_STATUS.FINISHED,
      color: 'success',
    },
    {
      label: i18n.t('waiting_baby_sister'),
      value: BOOKING_STATUS.WAITING_BABYSITTER,
      color: 'yellow',
    },
    {
      label: i18n.t('baby_sister_not_found'),
      value: BOOKING_STATUS.BABYSITTER_NOT_FOUND,
      color: 'yellow',
    },
    {
      label: i18n.t('upcoming'),
      value: BOOKING_STATUS.UPCOMING,
      color: 'secondary',
    },
    {
      label: i18n.t('request_finish'),
      value: BOOKING_STATUS.REQUEST_FINISH,
      color: 'blue',
    },
    {
      label: i18n.t('payment_link_error'),
      value: BOOKING_STATUS.PAYMENT_LINK_ERROR,
      color: 'yellow',
    },
    {
      label: i18n.t('late_payment'),
      value: BOOKING_STATUS.CANCELED_LATE_PAYMENT,
      color: 'blue',
    },
  ]
}

export const getStatus = (value: BOOKING_STATUS | string): OptionType | any => {
  const options = getStatuses()
  return _.find(options, { value })
}

export const isCanceledBabysitter = (value?: BOOKING_STATUS | string): boolean => {
  return value === BOOKING_STATUS.CANCELED_BABYSITTER
}

export const isCanceledAdmin = (value?: BOOKING_STATUS | string): boolean => {
  return value === BOOKING_STATUS.CANCELED_ADMIN
}

export const isCanceledLatePayment = (value?: BOOKING_STATUS | string): boolean => {
  return value === BOOKING_STATUS.CANCELED_LATE_PAYMENT
}
