import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Space } from '@mantine/core'
import { Button } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import {
  AboutBlock,
  CertificationsBlock,
  LanguagesBlock,
  SkillsBlock,
} from '@/features/babysitter/components/Profile/InfoBlocks'
import { BabysitterProfile, useUser } from '@/features/user'
import { ProfileDetailsLayout } from '../ProfileDetailsLayout'
import { General } from './General'

export const BabysitterProfileDetails = () => {
  const {
    appState: { mobileView },
  } = useAppState()

  const navigate = useNavigate()
  const { user } = useUser()
  const { t } = useTranslation()

  const { babysitter_profile } = user

  const { languages, skills, certifications } = babysitter_profile as BabysitterProfile
  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigate('/logout')
  }

  return (
    <>
      <ProfileDetailsLayout>
        <General />

        <Space h={'xl'} />

        <Grid>
          <Grid.Col md={6}>
            <div className={mobileView ? 'mb-1' : ''}>
              <AboutBlock editable={true} data={babysitter_profile} />
            </div>
          </Grid.Col>
          <Grid.Col md={6}>
            <div className={mobileView ? 'my-4' : 'mb-4'}>
              <LanguagesBlock editable={true} data={languages} />
            </div>

            <div className={'mb-4'}>
              <SkillsBlock editable={true} data={skills} />
            </div>

            <CertificationsBlock editable={true} data={certifications} />
          </Grid.Col>
        </Grid>

        {mobileView && (
          <div className={'w-100'}>
            <Button
              mt={'md'}
              fullWidth
              variant={'outline'}
              size={'md'}
              onClick={onClick}
              styles={(theme) => ({
                root: {
                  fontSize: '14px',
                },
              })}
            >
              {t('logout')}
            </Button>
          </div>
        )}
      </ProfileDetailsLayout>
    </>
  )
}
