import { useRef } from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { TextInputProps } from '@/components/FormElements/TextInput'
import { HtmlTimeInput } from '../../HtmlTimeInput/HtmlTimeInput'

interface TimeInputControllerProps<T extends FieldValues> extends TextInputProps {
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
  control: Control<T, any>
  getValues: any
  setValue: any
  watch: any
  label: string
  className?: any
  translateParams?: object
}

export const TimeController = <T extends FieldValues>({
  control,
  getValues,
  setValue,
  watch,
  name,
  label,
  meta,
  shouldUnregister,
  defaultValue,
  className,
  translateParams,
  ...rest
}: TimeInputControllerProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field: { ref, ...field }, fieldState, formState }) => {
          const meta = {
            error: fieldState.error,
          }

          return (
            <HtmlTimeInput
              {...field}
              className={className}
              label={label}
              meta={meta}
              watch={watch}
              setValue={setValue}
              {...rest}
            />
          )
        }}
      />
    </>
  )
}
