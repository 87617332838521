import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { useAppState, useEvents } from '@/features/app/hooks'
import { BookingLayout } from '@/features/booking/components/Booking'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'

interface IProps {
  frame?: boolean
  bookingType: BOOKING_TYPE
  step: number
}
export const New = ({ bookingType, step, frame = false }: IProps) => {
  const event = useEvents()
  const navigate = useNavigate()

  const {
    appState: { frameView },
  } = useAppState()

  useEffect(() => {
    if (!frame) sendMixPanelEvent()
    console.log('frame step:' + step)
    console.log('frameView:' + frameView)
    console.log('ignore:' + process.env.REACT_APP_IGNORE_FRAME_PATHS_CHECK)
    if (!frameView && !process.env.REACT_APP_IGNORE_FRAME_PATHS_CHECK) {
      navigate('/book-now')
    }
  }, [])

  const sendMixPanelEvent = () => {
    if (step == 0)
      event.actions.genericProps(TAG_MANAGER_EVENT.BOOK, {
        type:
          bookingType == BOOKING_TYPE.ONETIME
            ? TAG_MANAGER_EVENT.BOOK_ONETIME
            : bookingType == BOOKING_TYPE.REGULAR
            ? TAG_MANAGER_EVENT.BOOK_REGULAR
            : bookingType == BOOKING_TYPE.PERMANENT_NANNY
            ? TAG_MANAGER_EVENT.BOOK_NANNY
            : TAG_MANAGER_EVENT.BOOK_PARTY,
      })
  }

  return (
    <>
      <MegaTagTitle
        title={step == 0 ? 'new_booking' : step == 1 ? 'care_details' : 'payment_method'}
      />

      <BookingLayout bookingType={bookingType} frame={frame} step={step} />
    </>
  )
}
