import * as React from 'react'
import { AppLangPicker, LogoIcon } from '@/components/Elements'
import useStyles from './Header.styles'

interface IProps {
  siteRedirect?: boolean
}

export const Header = ({ siteRedirect }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.block}>
      <LogoIcon siteRedirect={siteRedirect} />

      <AppLangPicker />
    </div>
  )
}
