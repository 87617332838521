import _ from 'lodash'
import book_animation from '@/assets/images/booking/animacao_festa.png'
import book_onetime from '@/assets/images/booking/babysitting_ocasional.png'
import book_regular from '@/assets/images/booking/babysitting_regular.png'
import book_nanny from '@/assets/images/booking/nanny_permanente.png'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import i18n from '@/i18n'

type TypeOptionType = {
  title: string
  desc: string
  service: string
  path: string
  value: BOOKING_TYPE
}

export const getTypes = () => {
  return [
    {
      label: i18n.t('babysitting_one_time'),
      //info: i18n.t('booking_ocasional_info'),
      title: i18n.t('booking_ocasional.title'),
      desc: i18n.t('booking_ocasional.desc'),
      service: i18n.t('booking_ocasional.service'),
      value: BOOKING_TYPE.ONETIME,
      image: book_onetime,
      path: 'onetime',
      iconName: 'clock',
    },
    {
      label: i18n.t('babysitting_regular'),
      //info: i18n.t('booking_regular_info'),
      title: i18n.t('booking_regular.title'),
      desc: i18n.t('booking_regular.desc'),
      service: i18n.t('booking_regular.service'),
      value: BOOKING_TYPE.REGULAR,
      image: book_regular,
      path: 'regular',
      iconName: 'arrows-spin',
    },
    {
      label: i18n.t('booking_nanny.title'),
      //info: i18n.t('booking_animation_info'),
      title: i18n.t('booking_nanny.title'),
      desc: i18n.t('booking_nanny.desc'),
      service: i18n.t('booking_nanny.service'),
      value: BOOKING_TYPE.PERMANENT_NANNY,
      image: book_nanny,
      path: 'nanny',
      iconName: 'arrows-spin',
    },
    {
      label: i18n.t('babysitting_animation'),
      //info: i18n.t('booking_animation_info'),
      title: i18n.t('booking_animation.title'),
      desc: i18n.t('booking_animation.desc'),
      service: i18n.t('booking_animation.service'),
      value: BOOKING_TYPE.ANIMATION,
      image: book_animation,
      path: 'animation',
      iconName: 'arrows-spin',
    },
  ]
}

export const getType = (value: BOOKING_TYPE | string): TypeOptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}
