import React from 'react'
import { Grid } from '@mantine/core'
import { GeneralBookingCard } from '@/features/bookings/components/Bookings/Cards'
import { BookingModel } from '@/features/bookings/types/models'

interface IProps {
  items: BookingModel[]
  onView: (id: number) => void
  actionBarProps?: any
  onCancelSuccess?: () => void
  onBabysitterFavoriteSuccess?: (data: any) => void
  footerProps?: any
  onRatingSubmitted: (rating: any | null, actionMeta: any) => void
}

export const GridView: React.FC<IProps> = ({
  items,
  onView,
  actionBarProps,
  onCancelSuccess,
  onBabysitterFavoriteSuccess,
  footerProps,
  onRatingSubmitted,
  ...props
}) => {
  return (
    <>
      <Grid grow>
        {items.map((item, i) => (
          <Grid.Col key={item.id}>
            <GeneralBookingCard
              data={item}
              onView={onView}
              onCancelSuccess={onCancelSuccess}
              actionBarProps={actionBarProps}
              footerProps={footerProps}
              favoriteControlProps={{ onSuccess: onBabysitterFavoriteSuccess }}
              ratingControlProps={{ onRatingSubmitted }}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  )
}
