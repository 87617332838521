import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAuth } from '@/features/auth'
import { useUser } from '@/features/user/hooks'
import useStyles from './BackLink.styles'

interface IProps {
  goToBookNow?: boolean
}

export const BackLink = ({ goToBookNow = false }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()
  const { isLoggedIn } = useAuth()
  const { getBookingListEntryPath } = useUser()

  return (
    <div className={'flex'}>
      {isLoggedIn ? (
        <Link to={goToBookNow ? '/book-now' : getBookingListEntryPath()} className={classes.link}>
          <Group>
            <Icon name={'logout'} className={classes.linkIcon} />
            <Text>{t('back_to_bookings')}</Text>
          </Group>
        </Link>
      ) : (
        <Link to={/*goToBookNow ? '/book-now' :*/ '/'} className={classes.link}>
          <Group>
            <Icon name={'logout'} className={classes.linkIcon} />
            <Text>{t(/*goToBookNow ? 'back_to_bookings-now' :*/ 'back_to_login')}</Text>
          </Group>
        </Link>
      )}
    </div>
  )
}
