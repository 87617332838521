import { IconCirclePlus } from '@tabler/icons'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Anchor, Grid, Group, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import {
  DatePickerController,
  FieldError,
  FormControlActionBlock,
  TextAreaController,
  TextInputController,
} from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export type CertificationFieldValues = {
  school: string
  study: string
  description: string
  start_date: string | Date | null
  end_date: string | Date | null
}

interface IProps {
  name: string
  control: any
  limit?: number
  meta?: any
  clearErrors?: (name: any) => void
  gridProps?: any
}

export const CertificationsFieldArray = ({
  name,
  control,
  limit,
  meta,
  clearErrors,
  gridProps,
}: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const error = meta?.error

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  })

  const addRow = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (clearErrors) {
      clearErrors(name)
    }

    const fields: CertificationFieldValues = {
      school: '',
      study: '',
      description: '',
      start_date: null,
      end_date: null,
    }

    append(fields)
  }

  const removeRow = (index: number) => {
    remove(index)
  }

  const renderAdd = () => (
    <div className={'flex flex-col mt-4 self-start'}>
      <Anchor href="#" onClick={addRow} className={'font-bold add-new-item'}>
        <Group>
          <IconCirclePlus size={18} />
          {t('add_certification')}
        </Group>
      </Anchor>
      <FieldError error={error} />
    </div>
  )

  const renderTitleSection = (index: number) => (
    <Title order={5} my={'sm'}>
      {t('certification')} {index + 1}
    </Title>
  )

  return (
    <div className={'flex flex-col mb-4'}>
      {fields.map((item, index) => (
        <div key={item.fieldId} className={'flex flex-col'}>
          {renderTitleSection(index)}
          <Grid grow {...gridProps}>
            <Grid.Col span={9} lg={11} xs={10}>
              <Grid grow {...gridProps}>
                <Grid.Col>
                  <TextInputController
                    control={control}
                    name={`${name}.${index}.school`}
                    id={`${name}.${index}.school`}
                    label={t('school')}
                    placeholder={t('certification.school.field.placeholder')}
                    size={mobileView ? 'md' : 'lg'}
                    mb={mobileView ? '0' : 'md'}
                  />
                </Grid.Col>
                <Grid.Col>
                  <TextInputController
                    control={control}
                    name={`${name}.${index}.study`}
                    id={`${name}.${index}.study`}
                    label={t('study')}
                    placeholder={t('certification.study.field.placeholder')}
                    size={mobileView ? 'md' : 'lg'}
                    mb={mobileView ? '0' : 'md'}
                  />
                </Grid.Col>
                <Grid.Col>
                  <TextAreaController
                    control={control}
                    name={`${name}.${index}.description`}
                    id={`${name}.${index}.description`}
                    label={t('description')}
                    placeholder={t('certification.description.field.placeholder')}
                    size={mobileView ? 'md' : 'lg'}
                    mb={mobileView ? '0' : 'md'}
                  />
                </Grid.Col>
                <Grid.Col>
                  <Grid>
                    <Grid.Col md={6}>
                      <DatePickerController
                        control={control}
                        name={`${name}.${index}.start_date`}
                        id={`${name}.${index}.start_date`}
                        label={t('start_date')}
                        placeholder={t('start_date')}
                        size={mobileView ? 'md' : 'lg'}
                        mb={mobileView ? '0' : 'md'}
                      />
                    </Grid.Col>
                    <Grid.Col md={6}>
                      <DatePickerController
                        control={control}
                        name={`${name}.${index}.end_date`}
                        id={`${name}.${index}.end_date`}
                        label={t('end_date_or_expected')}
                        placeholder={t('end_date')}
                        size={mobileView ? 'md' : 'lg'}
                        mb={mobileView ? '0' : 'md'}
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={3} lg={1} xs={2}>
              <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
                <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
              </FormControlActionBlock>
            </Grid.Col>
          </Grid>
        </div>
      ))}
      {limit ? fields.length < limit && renderAdd() : renderAdd()}
    </div>
  )
}
