import { BookingModel } from '@/features/bookings/types/models'
import { ChatNavControl } from '@/features/chat/components/Elements'
import { ChatModel, Participant } from '@/features/chat/types/models'
import { BabysitterUser } from '@/features/user'

interface IProps {
  booking: BookingModel
  babysitters: BabysitterUser[]
  chat: ChatModel
  showButton?: boolean
}

export const ChatControl = ({ booking, babysitters, chat, showButton = false }: IProps) => {
  const result: Participant[] = []

  babysitters.map((babysitter) => {
    result.push({
      id: babysitter.id,
      avatar: babysitter.avatar,
      first_name: babysitter.first_name,
      last_name: babysitter.last_name,
      role: babysitter.role,
    })
  })

  chat = { ...chat, booking: booking }
  return <ChatNavControl chat={chat} participants={result} showButton={showButton} />
}
