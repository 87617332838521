import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ExceptionDatesController } from '@/features/booking/components/FormElements'

export const ExceptionDatesFormSection = () => {
  const { control, watch } = useFormContext()

  const watchDateStart = watch('date_start')
  const watchDateEnd = watch('date_end')

  return (
    <ExceptionDatesController
      control={control}
      name={'timetable.exception_dates'}
      period={[watchDateStart, watchDateEnd]}
    />
  )
}
