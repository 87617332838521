import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TimeController } from '@/components/FormElements/Controllers/TimeInputSelectController/TimeController'
import { useAppState } from '@/features/app/hooks'

export const TimePeriodFormSection = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { control, watch, setValue, getValues } = useFormContext()

  return (
    <>
      <Grid className={mobileView ? 'mb-2' : 'mb-3'}>
        <Grid.Col span={mobileView ? 6 : 4}>
          <TimeController
            control={control}
            name={'timetable.time_start'}
            id={'timetable.time_start'}
            label={t('from')}
            size={mobileView ? 'md' : 'lg'}
            mb={'md'}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>

        <Grid.Col span={mobileView ? 6 : 4}>
          <TimeController
            control={control}
            name={'timetable.time_end'}
            id={'timetable.time_end'}
            label={t('to')}
            size={mobileView ? 'md' : 'lg'}
            mb={'md'}
            className={mobileView ? 'ml-2' : ''}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            styles={
              mobileView
                ? {
                    label: {
                      fontWeight: 'bold',
                      fontSize: '16px !important',
                    },
                  }
                : {}
            }
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
