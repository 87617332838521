import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController, ZipInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { ExtendedCityFormSection } from '@/features/profile/components/FormSections'

export type LocationFormSectionValues = {
  city_id: number | string | null
  city_type: string
  city_description: string
  address: string
  zip: string
}

export const LocationFormSection = () => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()
  const { control } = useFormContext<LocationFormSectionValues>()

  return (
    <>
      <ExtendedCityFormSection />

      <Grid>
        <Grid.Col span={12}>
          <TextInputController
            control={control}
            name={'address'}
            id={'address'}
            label={t('address')}
            placeholder={t('address')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />
        </Grid.Col>
        <Grid.Col md={5}>
          <ZipInputController
            control={control}
            name={'zip'}
            id={'zip'}
            label={t('postal_code')}
            placeholder={t('postal_code')}
            size={mobileView ? 'md' : 'lg'}
            mb={mobileView ? '0' : 'md'}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
