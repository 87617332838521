import { useMixPanel, useTagManager } from '@/features/app/hooks'
import { Home } from '@/features/auth/routes'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'

export const useEvents = () => {
  const mixPanel = useMixPanel()
  const tagManager = useTagManager()

  const actions = {
    peopleSet: (props: any) => {
      mixPanel.actions.people.set(props)
    },
    appLoaded: (props: any) => {
      mixPanel.actions.track('app_loaded', props)
    },
    home: (props: any) => {
      mixPanel.actions.track('home', props)
    },
    auth: (props: any) => {
      mixPanel.actions.track('auth_page', props)
    },
    login: (props: any) => {
      mixPanel.actions.track('Login', props)
    },
    register: (props: any) => {
      mixPanel.actions.track(TAG_MANAGER_EVENT.REGISTER, props)
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.REGISTER)
    },
    bookingWithoutLogin: () => {
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.BOOKING_WITHOUT_LOGIN)
      mixPanel.actions.track(TAG_MANAGER_EVENT.BOOKING_WITHOUT_LOGIN, {})
    },
    newBooking: (props: any) => {
      mixPanel.actions.track(TAG_MANAGER_EVENT.NEW_BOOKING, { ...props })
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.NEW_BOOKING)
    },
    submissionAnimation: (props: any) => {
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.SUBMISSIONS_ENTERTAINMENT)
      mixPanel.actions.track(TAG_MANAGER_EVENT.SUBMISSION, { ...props, Type: 'animation' })
      mixPanel.actions.track(TAG_MANAGER_EVENT.CHECKOUT_SUBMISSION, { ...props, Type: 'animation' })
    },
    submissionNanny: (props: any) => {
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.SUBMISSIONS_NANNY)
      mixPanel.actions.track(TAG_MANAGER_EVENT.SUBMISSION, { ...props, Type: 'permanent_nanny' })
      mixPanel.actions.track(TAG_MANAGER_EVENT.CHECKOUT_SUBMISSION, {
        ...props,
        Type: 'permanent_nanny',
      })
    },
    beforeCheckout: (props: any) => {
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.BEFORE_CHECKOUT)
      mixPanel.actions.track(TAG_MANAGER_EVENT.BEFORE_CHECKOUT, props)
    },
    careDetails: (props: any) => {
      tagManager.actions.simpleEvent(TAG_MANAGER_EVENT.CARE_DETAILS)
      mixPanel.actions.track(TAG_MANAGER_EVENT.CARE_DETAILS, props)

      mixPanel.actions.people.set({
        $last_age_mean: props.AgeMean,
      })
    },
    purchaseType: (
      purchaseType: string,
      value: any,
      bookingId: any,
      firstTime: any,
      booking_alternative: string,
      booking_created_by_admin: boolean
    ) => {
      tagManager.actions.simpleEventWithValue(
        TAG_MANAGER_EVENT.PURCHASE + '_' + purchaseType,
        value
      )
      mixPanel.actions.track(TAG_MANAGER_EVENT.CHECKOUT, {
        Type: purchaseType,
        Value: value,
        Booking: bookingId,
        FirstTime: firstTime,
        CheckoutOrigin: 'app',
        BookingFlow: booking_alternative,
        BookingCreatedByAdmin: booking_created_by_admin,
      })
      mixPanel.actions.track(TAG_MANAGER_EVENT.CHECKOUT_SUBMISSION, {
        Type: purchaseType,
        Booking: bookingId,
      })
    },
    purchaseEcommerce: (value: any, ecommerce: any) => {
      tagManager.actions.simpleEventWithEcommerce(TAG_MANAGER_EVENT.PURCHASE, value, ecommerce)
    },
    shareSocialMedia: (social: string) => {
      mixPanel.actions.track('referral_share_open_' + social, {})
    },
    shareClickSocialMedia: (social: string, code: string | null) => {
      mixPanel.actions.track('referral_share_click_' + social, {
        referral: code,
      })
    },
    generic: (value: string) => {
      mixPanel.actions.track(value, {})
    },
    genericProps: (value: string, props: any) => {
      mixPanel.actions.track(value, props)
    },
  }
  return {
    actions,
  }
}
