import axios from 'axios'
import { GetBookingsDTO, SubmitBookingDTO } from './dto'

class BookingsAPI {
  createBooking = async (data: SubmitBookingDTO) => {
    return axios.post('api/bookings/v2/create-auth', data)
  }
  createBookingNoLogin = async (data: SubmitBookingDTO) => {
    return axios.post('api/bookings/v2/create', data)
  }

  createPaymentIntent = async (booking = {}, formData?: any) => {
    return axios.post('api/create-payment-intent', { params: { booking, formData } })
  }
  createPaymentIntentV2 = async (bookingId: any) => {
    return axios.post('api/bookings/v2/create-payment-intent', { params: { booking: bookingId } })
  }

  loadPaymentIntent = async (paymentIntentId: any) => {
    return axios.get('api/bookings/v2/pay/' + paymentIntentId)
  }

  setBookingConfirmed = async (booking_id: any, payment_intent_id: any) => {
    return axios.post('api/bookings/v2/confirm', {
      booking_id: booking_id,
      payment_intent_id: payment_intent_id,
    })
  }

  saveBookingNoPayment = async (booking = {}, formData?: any) => {
    return axios.post('api/save-booking-no-payment', { params: { booking, formData } })
  }

  saveCareDetails = async (data?: any) => {
    return axios.post('api/bookings/v2/save-last-details', data)
  }
  saveBookingAdmin = async (data?: any) => {
    return axios.post('api/bookings/v2/save-booking-admin', data)
  }

  saveBookingAndSendInvoice = async (booking: any, user?: any) => {
    return axios.post('api/save-booking-and-send-invoice', {
      params: { user, booking },
    })
  }

  getBookings = async (params: GetBookingsDTO) => {
    return axios.get(`api/bookings`, {
      params,
    })
  }
}

export const bookingsAPI = new BookingsAPI()
