import { useTranslation } from 'react-i18next'
import { Center, Space, Text, Title } from '@mantine/core'
import { ButtonLink, Card } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { Animation } from './Animation'
import useStyles from './Component.styles'

export const BookingConfirmedContainer = ({
  justCreatedUser,
  paymentConfirmed,
  frame,
  bookingId,
}: any) => {
  const { t } = useTranslation()

  const { isLoggedIn } = useAuth()
  const { classes } = useStyles()

  const {
    appState: { mobileView },
  } = useAppState()

  return (
    <Card className={classes.card}>
      <Center>
        <div className={classes.animationBlock}>
          <Animation />
        </div>
      </Center>

      <div className={classes.content}>
        <Center>
          <Title order={mobileView ? 3 : 1}>{t('booking_ocasional_title')}</Title>
        </Center>

        <Space h={'md'} />

        {!frame && (
          <>
            <div>
              <Text className={classes.info} weight={'600'} size={'sm'}>
                {isLoggedIn && !justCreatedUser
                  ? paymentConfirmed
                    ? t('booking_confirmed.info')
                    : t('booking_unconfirmed.info')
                  : t('booking_confirmed.info_user')}
              </Text>
            </div>

            <Space h={40} />

            {isLoggedIn ? (
              <ButtonLink fullWidth to={'/bookings'}>
                {t(!justCreatedUser ? 'access_my_account' : 'see_my_reservations')}
              </ButtonLink>
            ) : (
              <ButtonLink fullWidth to={'/login/client'}>
                {t('access_my_account')}
              </ButtonLink>
            )}

            <Space h={'md'} />
          </>
        )}
        {frame && (
          <>
            <div>
              <Text className={classes.info} weight={'600'} size={'sm'}>
                {'A booking with Service ID ' + String(bookingId).padStart(7, '0') + ' was created'}
              </Text>
            </div>
            <Space h={40} />

            <ButtonLink fullWidth to={'/booking/frame/new'}>
              {'New booking'}
            </ButtonLink>

            <Space h={'md'} />
          </>
        )}
      </div>
    </Card>
  )
}
