import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Booking } from '@/features/booking/routes/booking/Booking'
import { BookingsLayout, BookingsTabsLayout } from '@/features/bookings/components/Layouts'
import { useHeaderContext } from '@/features/header/contexts/HeaderContext'
import { History, Upcoming } from '../bookings'

export const BookingsRoot = () => {
  const { setKey } = useHeaderContext()
  const navigate = useNavigate()
  const handleNew = () => {
    navigate('/book-now')
  }
  const addBookingClickHandler = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault()

    handleNew()
  }

  useEffect(() => {
    setKey({
      base: true,
      title: 'bookings',
      border: false,
      leftIcon: 'plus',
      leftClick: addBookingClickHandler,
    })
  }, [])

  return (
    <Routes>
      <Route path={'booking/:id'} element={<Booking />} />
      <Route element={<BookingsLayout />}>
        <Route element={<BookingsTabsLayout />}>
          <Route index element={<Upcoming />} />
          <Route path={'history'} element={<History />} />
        </Route>
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
