import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { Grid, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import {
  BookingHoursPerWeekDisplay,
  BookingTimePeriodDisplay,
} from '@/features/bookings/components/Elements'
import { BookingDaysPerWeekDisplay } from '@/features/bookings/components/Elements/BookingDaysPerWeekDisplay/BookingDaysPerWeekDisplay'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { bookingHelper } from '@/features/bookings/helpers'
import { getType } from '@/features/bookings/helpers/booking/type'
import { BookingModel } from '@/features/bookings/types/models'
import { DateUtils } from '@/utils'
import useStyles from './DateBlock.styles'

const { bookingModel } = bookingHelper

interface IProps {
  data: BookingModel
}

export const DateBlock = ({ data }: IProps) => {
  const {
    appState: { mobileView },
  } = useAppState()
  const [dateEndToRender, setDateEndToRender] = useState(data.date_end)

  const { classes } = useStyles()
  const timePeriod = bookingModel.getTimePeriod(data)

  const { type, date_start, date_end, timetable } = data

  useEffect(() => {
    if (
      (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION) &&
      timetable?.onetime_ranges &&
      timetable.onetime_ranges.length > 0
    ) {
      let maxDate = moment(date_end)
      timetable?.onetime_ranges?.forEach(function (range: any) {
        if (maxDate.isBefore(moment(range.range_date_end))) {
          maxDate = moment(range.range_date_end)
          setDateEndToRender(range.range_date_end)
        }
      })
    }
  }, [])
  const renderDate = (date: string | null) => {
    if (!date) return <div className={'flex flex-col items-center'}></div>

    const dayWeek = DateUtils.format(date, 'ddd')
    const day = DateUtils.format(date, 'D')
    const month = DateUtils.format(date, 'MMM')

    return (
      <div className={'flex flex-col items-center'}>
        <Text size={15} weight={700} color={'secondary'}>
          {dayWeek}
        </Text>
        <Text size={32} weight={700}>
          {day}
        </Text>
        <Text weight={500} color={'gray.6'}>
          {month}
        </Text>
      </div>
    )
  }

  const renderDateMobile = (date: string | null) => {
    if (!date) return <div className={'flex gap-1'}></div>

    const dayWeek = DateUtils.format(date, 'ddd')
    const day = DateUtils.format(date, 'D')
    const month = DateUtils.format(date, 'MMM')

    return (
      <div className={'flex gap-1'}>
        <Text size={14} weight={700} color={'secondary'}>
          {dayWeek}
        </Text>
        <Text size={14} weight={700}>
          {day}
        </Text>
        <Text size={14} weight={500} color={'gray.6'}>
          {month}
        </Text>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {mobileView && (
        <div className={classes.dateBlock}>
          <Grid>
            <Grid.Col span={12}>
              <div className={'flex gap-1'}>
                {date_start === dateEndToRender ? (
                  renderDateMobile(date_start)
                ) : (
                  <>
                    {renderDateMobile(date_start)}

                    <Text size={14} weight={700}>
                      {' - '}
                    </Text>

                    {renderDateMobile(dateEndToRender)}
                  </>
                )}
                {timePeriod && (
                  <>
                    <Text size={14} weight={700}>
                      {', '}
                    </Text>
                    <Text size={14} weight={700}>
                      <BookingTimePeriodDisplay timePeriod={timePeriod} />
                      <Icon
                        className="ml-2"
                        name={getType(type)?.iconName}
                        size={mobileView ? 18 : 23}
                      />
                    </Text>
                  </>
                )}
                {data.hours_per_week && (
                  <>
                    <Text size={14} weight={700}>
                      {', '}
                    </Text>
                    <Text size={14} weight={700}>
                      <BookingHoursPerWeekDisplay hoursPerWeek={data.hours_per_week} />
                      <Icon
                        className="ml-2"
                        name={getType(type)?.iconName}
                        size={mobileView ? 18 : 23}
                      />
                    </Text>
                  </>
                )}
                {data.days_per_week && (
                  <>
                    <Text size={14} weight={700}>
                      {', '}
                    </Text>
                    <Text size={14} weight={700}>
                      <BookingDaysPerWeekDisplay daysPerWeek={data.days_per_week} />
                      <Icon
                        className="ml-2"
                        name={getType(type)?.iconName}
                        size={mobileView ? 18 : 23}
                      />
                    </Text>
                  </>
                )}
              </div>
            </Grid.Col>
          </Grid>
        </div>
      )}

      {!mobileView && (
        <>
          <div className={classes.dateBlock}>
            {date_start === dateEndToRender ? (
              renderDate(date_start)
            ) : (
              <div className={'flex gap-2 items-center justify-center'}>
                {renderDate(date_start)}

                <Text size={32} weight={700}>
                  {' - '}
                </Text>

                {renderDate(dateEndToRender)}
              </div>
            )}
          </div>
          <div className={classes.divider} />
        </>
      )}
    </div>
  )
}
