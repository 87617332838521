import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'

interface IProps {
  type:
    | 'has_unpaid_invoice'
    | 'unpaid'
    | 'canceled_babysitter'
    | 'canceled_admin'
    | 'canceled_late_payment'
  alertProps?: any
  className?: string
  children?: React.ReactNode
}

export const BookingInfoAlert: React.FC<IProps> = ({
  type,
  alertProps,
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation()

  const config = useMemo(() => {
    let data: any = {
      type: 'info',
      message: '',
    }
    switch (type) {
      case 'has_unpaid_invoice':
        data = {
          type: 'info',
          message: t('need_payment'),
        }
        break

      case 'unpaid':
        data = {
          type: 'info',
          message: t('need_payment'),
        }
        break

      case 'canceled_babysitter':
        data = {
          type: 'error',
          message: t('baby_sister_canceled_booking'),
        }
        break

      case 'canceled_admin':
        data = {
          type: 'error',
          message: t('admin_canceled_booking'),
        }
        break
      case 'canceled_late_payment':
        data = {
          type: 'error',
          message: t('canceled_late_payment'),
        }
        break

      default:
        data = { ...data }
        break
    }
    return data
  }, [type])

  return (
    <Alert type={config.type} {...alertProps} className={className}>
      {children || config.message}
    </Alert>
  )
}
