import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ROLE, useUser } from '@/features/user'

interface IProps {
  [x: string]: any
}

export const PanelSwitch: React.FC<IProps> = ({ children, roles, ...props }) => {
  const location = useLocation()
  const { user } = props
  const { getBookingListEntryPath } = useUser()

  const path =
    user.role === ROLE.Client
      ? user.has_bookings
        ? getBookingListEntryPath()
        : '/book-now'
      : '/services/upcoming'

  return <Navigate to={path} state={{ from: location }} />
}
