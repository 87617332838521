import React from 'react'
import { Grid } from '@mantine/core'
import { GeneralServiceCard } from '@/features/booking-service/components/Services/Cards'
import { BookingService } from '@/features/bookings/types/models'

interface IProps {
  items: BookingService[]
  onView: (id: number) => void
  actionBarProps?: any
  onCandidateAcceptSuccess: () => void
  onCandidateDeclineSuccess: () => void
  onCancelSuccess?: () => void
  footer?: boolean
  footerProps?: any
  onRatingSubmitted: (rating: any | null, actionMeta: any) => void
}

export const GridView: React.FC<IProps> = ({
  items,
  onView,
  actionBarProps,
  onCandidateAcceptSuccess,
  onCandidateDeclineSuccess,
  onCancelSuccess,
  footer = false,
  footerProps,
  onRatingSubmitted,
  ...props
}) => {
  return (
    <>
      <Grid grow>
        {items.map((item, i) => (
          <Grid.Col key={item.id}>
            <GeneralServiceCard
              data={item}
              onView={onView}
              onCancelSuccess={onCancelSuccess}
              actionBarProps={actionBarProps}
              candidateProps={{
                candidate: item.my_booking_candidate,
                acceptControlProps: {
                  onSuccess: onCandidateAcceptSuccess,
                },
                declineControlProps: {
                  onSuccess: onCandidateDeclineSuccess,
                },
              }}
              footer={footer}
              footerProps={footerProps}
              ratingControlProps={{ onRatingSubmitted }}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  )
}
