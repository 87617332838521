import Plausible from 'plausible-tracker'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MegaTagTitle } from '@/components/Elements'
import { LoginModal } from '@/components/Elements/Modals/LoginModal/LoginModal'
import { useEvents } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import {
  BookingRegularConfirmedContainer,
  Layout,
} from '@/features/booking/components/BookingRegularConfirmed'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useUser } from '@/features/user'
import { ageMean } from '../../components/Booking/BookingContainer/BookingWizardForm/helpers'

export const RegularConfirmed = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const events = useEvents()
  const { isLoggedIn } = useAuth()
  const { getBookingListEntryPath } = useUser()
  const { trackEvent } = Plausible({
    domain: 'babysisters.pt',
  })
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const [justCreatedUser, setJustCreatedUser] = useState(false)
  const [type, setType] = useState(BOOKING_TYPE.REGULAR)
  const [visibleLoginModal, setVisibleLoginModal] = useState(false)

  useEffect(() => {
    if (!booking) {
      navigate(getBookingListEntryPath())
    } else {
      const type = booking.type
      const total = (booking.total_amount / 100).toFixed(2)
      const bookingId = booking.id

      trackEvent(
        type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.REGULAR
          ? 'booking/confirmed'
          : type + '/confirmed'
      )
      try {
        events.actions.careDetails({
          Type: type,
          Value: total,
          Booking: bookingId,
          AgeMean: ageMean(booking.booking_children),
        })
      } catch (e) {
        console.error('Error sending careDetails event')
        console.error(e)
      }

      setType(booking.type)
      setJustCreatedUser(booking.just_created_user)
    }
    if (isLoggedIn) {
      dispatch(checkoutState.setBooking(null))
    } else {
      setVisibleLoginModal(!booking.created_admin)
      localStorage.setItem('confirmed-booking-without-user', booking.id)
      dispatch(checkoutState.setBooking(null))
    }
  }, [])

  const onClose = () => {
    setVisibleLoginModal(false)
  }
  const onGuest = async () => {
    localStorage.removeItem('confirmed-booking-without-user')
    setVisibleLoginModal(false)
  }

  return (
    <>
      <MegaTagTitle title={'booking_confirmed'} />

      <Layout>
        <BookingRegularConfirmedContainer justCreatedUser={justCreatedUser} type={type} />
      </Layout>
      <LoginModal visible={visibleLoginModal} onLoginModalClose={onClose} onGuestClose={onGuest} />
    </>
  )
}
