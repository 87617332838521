import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { debounce, isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Group } from '@mantine/core'
import { Alert, Button, ContentCard } from '@/components/Elements'
//import { LoginModal } from '@/components/Elements/Modals/LoginModal/LoginModal'
import { HOTEL_TYPE } from '@/const/hotel'
import { useAppState } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { authLoginPA } from '@/features/auth/store'
import useStyles from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingWizardForm.styles'
import { DataForm } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/GeneralForm/DataForm'
import { GeneralFormNoLogin } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/GeneralForm/GeneralFormNoLogin'
import {
  getDaysScheduleDefaultValues,
  mapDefaultValuesFromPreviousRequest,
  mapDefaultValuesFromWorkingBooking,
  mapSubmitValues,
} from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/helpers'
import { getValidationSchema } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/validation'
import { bookingCreatePA } from '@/features/booking/store'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { bookingCreateNoLoginPA } from '@/features/booking/store/createNoLogin/saga'
import { previousRequestState } from '@/features/booking/store/previousRequest.slice'
import { bookingAPI } from '@/features/bookings/api'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingOptions } from '@/features/bookings/hooks'
import { useUser } from '@/features/user'
import { useFormSubmit, useLang, useNotify } from '@/hooks'
import { BookingLocation } from '../../types'

interface IProps {
  initialValuesBooking: { general: object; timetable: object }
  initialValuesDetails: { general: object }
  previousRequest: any
  loadedWorkingBooking: any
  bookingType: BOOKING_TYPE
  frame: boolean
}
export const GeneralForm = ({
  initialValuesBooking,
  initialValuesDetails,
  previousRequest,
  loadedWorkingBooking,
  bookingType,
  frame,
}: IProps) => {
  const { t } = useTranslation()
  const { showNotification } = useNotify()
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuth()
  const viewport = useRef<HTMLDivElement>()
  const {
    appState: { mobileView },
  } = useAppState()

  const [showUserDataForm, setShowUserDataForm] = useState(false)
  const [location, setLocation] = useState<BookingLocation | null>(null)
  const [locationNotFound, setLocationNotFound] = useState<boolean>(false)
  const [calculatingPrice, setCalculatingPrice] = useState<boolean>(false)
  const [priceError, setPriceError] = useState<boolean>(false)
  const [calculatedPrice, setCalculatedPrice] = useState<number>(0)
  const [priceAnalysis, setPriceAnalysis] = useState<any>({})
  const { getType } = useBookingOptions()
  const locationRef = useRef<BookingLocation | null>(null)
  const skipNextCalcRef = useRef<boolean>(false)
  const previousPriceRequestRef = useRef<any>({})
  //const [visibleLoginModal, setVisibleLoginModal] = useState(false)

  const { lang } = useLang()
  const { user } = useUser()
  const navigate = useNavigate()

  const goNext = () => {
    if (
      (bookingType === BOOKING_TYPE.ONETIME || bookingType === BOOKING_TYPE.REGULAR) &&
      isLoggedIn &&
      !frame
    ) {
      navigate('/booking/' + getType(bookingType).path + '/checkout', { replace: true })
    } else {
      if (frame) {
        navigate('/booking/frame/care-details', { replace: true })
      } else navigate('/booking/' + getType(bookingType).path + '/care-details', { replace: true })
    }
  }

  const defaultValues = {
    name: '',
    email: '',
    //city_id: null,
    //city_type: '',
    //city_description: '',
    //country_id: null,
    lat: 0,
    lng: 0,
    is_hotel: false,
    hotel_id: null,
    // hotel_name: '', // hidden field, depend on 'is_hotel'
    //address: '',
    //floor: '',
    //door_number: '',
    zip: '',
    type: bookingType,
    date_start: null,
    date_end: null,
    //comments: '',
    living_in: false,
    days_per_week: null,
    timetable: {
      time_start: null,
      time_end: null,
      same_weeks_schedule: false,
      same_days_time: false,
      weeks_schedule: [],
      days_schedule: getDaysScheduleDefaultValues(),
      exception_dates: [],
      onetime_ranges: [],
      ...initialValuesBooking?.timetable,
    },
    booking_children: [],
    primary_language_id: lang == 'en' ? 2 : 1,
    secondary_language_id: null,
    has_special_needs: false,
    special_needs: '',
    phone: null,
    discount_code: null,
    // special_needs: '', // hidden field, depend on 'has_special_needs'
    //other_info: '',
    care_expectations: '',

    ...initialValuesBooking?.general,
    ...initialValuesDetails?.general,
  }
  const currentValidationSchema = getValidationSchema({
    step: !showUserDataForm ? 0 : 1,
    hasUser: isLoggedIn,
    isHotel: false,
    frame: frame,
  })

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
  })

  const {
    handleSubmit,
    trigger,
    setError,
    watch,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = methods

  const watchForm = watch()

  const watchValuesPrice = watch([
    'is_hotel',
    'hotel_id',
    'timetable',
    'has_special_needs',
    'living_in',
    'days_per_week',
    'discount_code',
  ])
  const watchType = watch('type')
  const watchHotelId = watch('hotel_id')
  const watchIsHotel = watch('is_hotel')

  const onLocationChange = (data: BookingLocation | null) => {
    setLocation(data)
  }

  const onLocationNotFound = (data: boolean) => {
    console.log('onLocationNotFound: ' + data)
    if (data) setCalculatedPrice(0)
    setLocationNotFound(data)
  }

  const scrollToTop = () => {
    if (viewport?.current) {
      viewport.current.scrollTo({ top: 0 })
    } else {
      window.scrollTo(0, 0)
    }
  }
  const isZipValid = (zip: string) => {
    const re = new RegExp('^[0-9]{4}-[0-9]{3}$')
    return re.test(zip)
  }

  const getCalculatedPrice = async () => {
    setCalculatingPrice(true)
    const hotelId = getValues('hotel_id')
    const isHotel = getValues('is_hotel')
    const daysPerWeek = getValues('days_per_week')
    const livingIn = getValues('living_in')
    const type = getValues('type')
    const zip = getValues('zip')
    /*
    console.log(
      `getCalculatedPrice type:` +
        type +
        ' isHotel:' +
        isHotel +
        ' hotelId:' +
        hotelId +
        ' zip:' +
        zip
    )*/
    if (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.REGULAR) {
      if (
        (locationRef.current &&
          locationRef.current.position &&
          locationRef.current.position.lat &&
          locationRef.current.position.lng &&
          locationRef.current.position.lat !== 0 &&
          locationRef.current.position.lng !== 0 &&
          isZipValid(zip) &&
          (!isHotel || (hotelId && hotelId === HOTEL_TYPE.OTHER))) ||
        (isHotel && hotelId && hotelId !== HOTEL_TYPE.OTHER)
      ) {
        //console.log('calc values')
        const values = {
          ...mapSubmitValues(getValues(), type == BOOKING_TYPE.ONETIME),
          ...locationRef.current?.position,
        }
        if (!isEqual(previousPriceRequestRef.current, values)) {
          previousPriceRequestRef.current = values
          //console.log('price temp values not equal:' + JSON.stringify(values))
          let calcPrice =
            values.date_end !== 'Invalid date' &&
            values.data_start !== 'Invalid date' &&
            values.timetable.time_end !== null &&
            values.timetable.time_start !== null &&
            (values.type != BOOKING_TYPE.PERMANENT_NANNY || (livingIn && daysPerWeek) || !livingIn)

          //console.log('calc price 1:' + calcPrice)
          values.timetable.onetime_ranges.forEach(function (value: any) {
            calcPrice =
              calcPrice &&
              value.range_time_start !== null &&
              value.range_time_end !== null &&
              value.range_date_start !== 'Invalid date' &&
              (type === BOOKING_TYPE.ONETIME || value.range_date_end !== 'Invalid date')
          })
          if (
            calcPrice &&
            values.timetable.time_end !== null &&
            values.timetable.time_start !== null
          ) {
            //console.log('values:' + JSON.stringify(values))
            if (values.type === BOOKING_TYPE.ONETIME || values.type === BOOKING_TYPE.REGULAR) {
              // TODO remove this when calculator is implemented
              console.log('price values: ' + JSON.stringify(values))
              try {
                const { data } = await bookingAPI.getCalculatedPrice(isLoggedIn, values)
                setCalculatedPrice(data.price)
                setPriceError(false)
                if (
                  !priceAnalysis ||
                  JSON.stringify(data.bookingTableInformations) !== JSON.stringify(priceAnalysis)
                ) {
                  setPriceAnalysis(data.bookingTableInformations)
                }
                skipNextCalcRef.current = true
              } catch (error: any) {
                const message = error?.response?.data?.message || t('price_calc_error')
                showNotification({
                  type: 'error',
                  message: message,
                })

                if (!priceError) {
                  setPriceError(true)
                  setCalculatedPrice(0)
                  setPriceAnalysis({})
                  skipNextCalcRef.current = true
                }
              }
            }
          }
        } else {
          //console.log('price temp is equal')
        }
      } else {
        //console.log('price values: SET 0')
        setCalculatedPrice(0)
        setPriceAnalysis({})
        previousPriceRequestRef.current = {}
        skipNextCalcRef.current = true
      }
    }
    setCalculatingPrice(false)
  }

  const debouncedCalcPrice = React.useCallback(debounce(getCalculatedPrice, 500), [])

  useEffect(() => {
    scrollToTop()
    //if (!isLoggedIn) setVisibleLoginModal(true)
  }, [])

  useEffect(() => {
    if (!frame) {
      if (
        getValues('date_start') ||
        getValues('timetable.time_start') ||
        getValues('timetable.time_end')
      ) {
        const values = {
          ...getValues(),
        }
        localStorage.setItem('working-booking', JSON.stringify(values))
      }
    }
  }, [watchForm])

  useEffect(() => {
    if (skipNextCalcRef.current) {
      skipNextCalcRef.current = false
    } else {
      locationRef.current = location
      debouncedCalcPrice()
    }
  }, [watchValuesPrice, location])

  const showUserForm = async () => {
    const isStepValid = await trigger()
    if (isStepValid && (location || (watchIsHotel && !!watchHotelId)) && !priceError) {
      setShowUserDataForm(true)
    } else if (priceError) {
      showNotification({
        type: 'error',
        message: t('price_calc_error'),
      })
    } else {
      showNotification({
        type: 'error',
        message: t('please_fill_all_required_fields'),
      })
    }
  }

  useEffect(() => {
    if (previousRequest) {
      const data = mapDefaultValuesFromPreviousRequest(
        previousRequest,
        bookingType,
        initialValuesBooking,
        initialValuesDetails
      )
      //console.log('previous request: ' + JSON.stringify(data))
      reset(data)
    }
  }, [previousRequest])

  useEffect(() => {
    if (loadedWorkingBooking?.date_start && !previousRequest) {
      const data = mapDefaultValuesFromWorkingBooking(
        loadedWorkingBooking,
        bookingType,
        initialValuesBooking,
        initialValuesDetails,
        lang
      )
      console.log('loaded working: ' + JSON.stringify(data))
      reset(data)
    }
  }, [loadedWorkingBooking])

  const onSubmitBooking = async (values: any) => {
    if (!frame) {
      let data
      if (!user) {
        values.price_analysis = priceAnalysis
        data = await dispatch(bookingCreateNoLoginPA.request(values))
        /*if (data && data.token) {
        await dispatch(
          authLoginPA.request({ email: values.email, token: data?.token, language: i18n.language })
        )
        data.booking.just_created_user = true
      } else {
        data.booking.just_created_user = false
      }*/
        await dispatch(
          checkoutState.setBooking({ ...data.booking, booking_children: values.booking_children })
        )
      } else {
        values.price_analysis = priceAnalysis
        data = await dispatch(bookingCreatePA.request(values))
        data.just_created_user = false
        await dispatch(
          checkoutState.setBooking({ ...data, booking_children: values.booking_children })
        )
      }
      if (previousRequest) {
        await dispatch(previousRequestState.setBooking(null))
      }
      localStorage.removeItem('working-booking')
    } else {
      values.price_analysis = priceAnalysis
      await dispatch(checkoutState.setBooking({ ...values }))
    }
    goNext()
  }

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitBooking,
    setError,
  })

  const onError = (errors: any, e: any) => {
    // console.log('Validation failed:')
    // console.log(errors) // Logs all validation errors
    // console.log('Invalid fields:', Object.keys(errors)) // Logs the paths of invalid fields
    const message = errors?.response?.data?.message || t('please_fill_all_required_fields')

    showNotification({
      type: 'error',
      message: message,
    })
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    const isStepValid = await trigger()

    if (isStepValid && (location || (watchIsHotel && !!watchHotelId)) && !priceError) {
      try {
        const values = {
          ...mapSubmitValues(data, watchType == BOOKING_TYPE.ONETIME),
          ...location?.position,
          lang,
        }
        await onFormSubmit(values)
        // eslint-disable-next-line
      } catch (err: any) {
        const error = err?.message || ''
        showNotification({
          type: 'error',
          message: t('error_submitting_request') + ' - ' + error,
        })
      }
    } else if (priceError) {
      showNotification({
        type: 'error',
        message: t('price_calc_error'),
      })
    } else if (!(location || (watchIsHotel && !!watchHotelId))) {
      showNotification({
        type: 'error',
        message: t('location_check_error'),
      })
    } else {
      showNotification({
        type: 'error',
        message: t('please_fill_all_required_fields'),
      })
    }
  }

  const payDisabled =
    calculatingPrice ||
    (calculatedPrice < 0.01 &&
      (watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR))

  const renderVat = () => {
    const priceWithoutVAT = calculatedPrice / (1 + priceAnalysis['Vat'] / 100)

    const vatAmount = priceWithoutVAT * (priceAnalysis['Vat'] / 100)
    return vatAmount.toFixed(2)
  }

  const renderTooltip = () => {
    return (
      <>
        {priceAnalysis && !!priceAnalysis['Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.hours') + ': ' + priceAnalysis['Hours']} *</span>
            <span>{priceAnalysis['Amount']}€</span>
            <span>= {priceAnalysis['Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivity Price'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.festivities') + ': ' + priceAnalysis['Festivity Price']}€</span>
          </div>
        )}
        {/*{priceAnalysis && !!priceAnalysis['Morning Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.morning_hours') + ': ' + priceAnalysis['Morning Hours']} *</span>
            <span>{priceAnalysis['Morning Amount']}€</span>
            <span>= {priceAnalysis['Morning Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price Morning'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.festivities_morning') + ': ' + priceAnalysis['Festivities Price Morning']}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Evening Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.evening_hours') + ': ' + priceAnalysis['Evening Hours']} *</span>
            <span>{priceAnalysis['Evening Amount']}€</span>
            <span>= {priceAnalysis['Evening Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price Evening'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.festivities_evening') + ': ' + priceAnalysis['Festivities Price Evening']}€
            </span>
          </div>
        )}*/}
        {priceAnalysis && !!priceAnalysis['Distance Price'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.mileage_fee') + ': ' + priceAnalysis['Distance Price'].toFixed(2)}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Urgency Fee'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.urgency_fee') + ': ' + priceAnalysis['Urgency Fee']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Special Needs Fee'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.special_needs_fee') + ': ' + priceAnalysis['Special Needs Amount']}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Bonus'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.bonus') + ': ' + priceAnalysis['Bonus']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Vat'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.vat') + ': ' + renderVat()}€</span>
          </div>
        )}
        {priceAnalysis &&
          !!priceAnalysis['pricesPerMonth'] &&
          Object.keys(priceAnalysis['pricesPerMonth']).length > 1 && (
            <>
              <div className="flex gap-1 mt-2 font-bold">
                <span>{t('price.per_month') + ''}</span>
              </div>
              {Object.keys(priceAnalysis['pricesPerMonth']).map((key, index) => (
                <>
                  {index > 0 && (
                    <div key={index} className="flex gap-1 font-bold">
                      <span>
                        {t('month') + ' '}
                        {key + ': ' + priceAnalysis['pricesPerMonth'][key].toFixed(2)}€
                      </span>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
      </>
    )
  }

  const renderFooter = () => {
    return (
      <div
        className={classNames(
          classes.footerPriceMobile,
          'flex',
          'justify-between',
          'items-center',
          {
            'sticky-footer': mobileView,
            'z-index': mobileView,
          }
        )}
      >
        {/*((watchType !== BOOKING_TYPE.ONETIME && watchType !== BOOKING_TYPE.REGULAR) ||
          showUserDataForm) && <div></div>*/}
        <div></div>
        {/*mobileView &&
          (watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR) &&
          !showUserDataForm && (
            <div className={'flex flex-col'}>
              <Tooltip
                label={renderTooltip()}
                position="top"
                //withArrow
                //arrowSize={6}
                multiline
                events={{ hover: true, touch: true, focus: false }}
                disabled={calculatedPrice < 0.01}
              >
                <h2 className="flex gap-4 items-center cursor-pointer">
                  <span className={classes.price}>
                    {t(watchType === BOOKING_TYPE.REGULAR ? 'price.1st_month' : 'price')}
                  </span>
                  <i className="pi pi-info-circle"></i>
                </h2>
              </Tooltip>
              <h2 className="flex gap-4 items-center cursor-pointer" style={{ height: '30px' }}>
                {!calculatingPrice ? (
                  <span>€{calculatedPrice?.toFixed(2)}</span>
                ) : (
                  <i className="pi pi-spin pi-spinner"></i>
                )}
              </h2>
            </div>
          )*/}

        {/*!mobileView &&
          (watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR) &&
          !showUserDataForm && (
            <Tooltip
              label={renderTooltip()}
              position="top"
              withArrow
              //arrowSize={6}
              multiline
              events={{ hover: true, touch: true, focus: false }}
              disabled={calculatedPrice < 0.01}
            >
              <h2 className="flex gap-4 items-center cursor-pointer">
                <span>
                  {t(watchType === BOOKING_TYPE.REGULAR ? 'price.1st_month' : 'price')}:{' '}
                  {!calculatingPrice ? (
                    <>{calculatedPrice?.toFixed(2)}€</>
                  ) : (
                    <i className="pi pi-spin pi-spinner"></i>
                  )}
                </span>
                <i className="pi pi-info-circle"></i>
              </h2>
            </Tooltip>
          )*/}

        <Group>
          {/*step !== 0 && !mobileView && (
            <Button
              disabled={isSubmitting}
              variant="default"
              color="dark"
              leftIcon={<IconChevronLeft />}
              onClick={handleBack}
            >
              {t('back')}
            </Button>
          )*/}
          {/*(user || showUserDataForm) && (*/}
          <Button
            loading={isSubmitting}
            disabled={payDisabled}
            onClick={handleSubmit(onSubmit, onError)}
          >
            {t('booking.continue')}
          </Button>
          {/*)*/}
          {/*!user && !showUserDataForm && (
            <Button onClick={showUserForm} disabled={payDisabled}>
              {t('booking.continue')}
            </Button>
          )*/}
        </Group>
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form className={mobileView ? '' : 'h-full'}>
        <ContentCard
          title={
            frame
              ? undefined
              : t(showUserDataForm ? 'fill_contact_details' : 'book_baby_sister').replace(
                  '%BOOKING_TYPE%',
                  getType(watchType)?.title
                )
          }
          titleInfo={frame || showUserDataForm ? undefined : t('booking_title_info')}
          footer={renderFooter()}
          scrollable={!mobileView}
          viewport={viewport}
          className={classes.cardWrapper}
        >
          <div className={classes.wrapper}>
            {submitError && (
              <Alert type={'error'} mb={'sm'}>
                {submitError?.message || t('error')}
              </Alert>
            )}
            {!showUserDataForm ? (
              <DataForm
                location={location}
                onLocationChange={onLocationChange}
                locationNotFound={locationNotFound}
                onLocationNotFound={onLocationNotFound}
                frame={frame}
              />
            ) : (
              <GeneralFormNoLogin />
            )}
          </div>
        </ContentCard>
      </form>
      {/*<LoginModal visible={visibleLoginModal} />*/}
    </FormProvider>
  )
}
