import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Anchor, Space, Title } from '@mantine/core'
import { Alert, Card } from '@/components/Elements'
import { authForgotPasswordPA } from '../../../store'
import { ForgotPasswordForm, FormValues } from './ForgotPasswordForm'

interface IProps {
  fromPopup?: boolean
  onGoBack?: () => void
}

export const ForgotPasswordContainer = ({ fromPopup = false, onGoBack }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const location = useLocation()

  const [success, setSuccess] = useState<string | null>(null)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(null)

    const { message } = await dispatch(authForgotPasswordPA.request(values))

    setSuccess(message || t('success'))
  }

  const createForm = () => (
    <>
      <Title order={3}>{t('forgot_password')}</Title>
      <Space h={'lg'} />
      {success && (
        <Alert type={'success'} mb={'sm'}>
          {success}
        </Alert>
      )}
      <ForgotPasswordForm onSubmit={handleSubmit} />
      <Space h={'lg'} />
      <div className={'mt-4 flex'}>
        <Title order={6}>
          {t('did_you_remember_it')}?{' '}
          {fromPopup ? (
            <Anchor onClick={onGoBack}>{t('login.access_here.text')}</Anchor>
          ) : (
            <Anchor component={Link} to={(location?.state as any)?.from || '/'}>
              {t('login.access_here.text')}
            </Anchor>
          )}
        </Title>
      </div>
    </>
  )

  return <>{fromPopup ? createForm() : <Card>{createForm()}</Card>}</>
}
