import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { BookingFormTimetableProvider } from '@/features/booking/contexts'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
//import { formHelper } from '@/features/profile'
import { ClientProfile, LanguageType, useUser } from '@/features/user'
import { BookingWizardForm } from './BookingWizardForm'

//const { clientProfile: clientProfileHelper } = formHelper

interface IProps {
  step: number
  bookingType: BOOKING_TYPE
  frame: boolean
}

export const BookingContainer = ({ step, bookingType, frame }: IProps) => {
  const [workingBooking, setWorkingBooking] = useState(undefined)
  const { user } = useUser()

  const { client_profile, phone } = user || {}

  const {
    //city,
    //city_type,
    //city_description,
    //country,
    address,
    zip,
    children,
    languages,
    about_child,
    other_info,
    //floor,
  } = (client_profile as ClientProfile) || {}

  const initialValuesBooking = useMemo(() => {
    let initial = { general: {}, timetable: {} }
    if (client_profile) {
      let result = {}

      /*const cityDefaultValue = clientProfileHelper.getCityDefaultValue({
        city,
        city_type,
        city_description,
        country,
      })*/

      result = {
        //...cityDefaultValue,
        address: address || '',
        //floor: floor || '',
        zip: zip || '',
      }

      initial = {
        general: result,
        timetable: {},
      }
    }
    return initial
  }, [client_profile])

  const initialValuesDetails = useMemo(() => {
    let initial = { general: {} }
    if (client_profile) {
      let result = {}

      result = {
        booking_children: children,
        phone: phone,
        //other_info: other_info || '',
        care_expectations: (about_child || '') + ' ' + (other_info || ''),
        primary_language_id: languages?.[0]?.language?.id?.toString() || null,
        secondary_language_id: languages?.[1]?.language?.id?.toString() || null,
      }

      initial = {
        general: result,
      }
    }
    return initial
  }, [client_profile])

  const { previousRequest } = useSelector((state: any) => state.booking.previousRequest)

  useEffect(() => {
    if (!frame && workingBooking == undefined) {
      //console.log('--- run use effect working-booking')
      const items = localStorage.getItem('working-booking')
      if (items) {
        const data = JSON.parse(items)
        if (data.type === bookingType) {
          setWorkingBooking(data)
          //console.log('-- effect run:' + JSON.stringify(data) + '. booking type matches')
        } else {
          //console.log('-- effect run:' + JSON.stringify(data) + '. booking type doesnt match')
          localStorage.removeItem('working-booking')
          setWorkingBooking(JSON.parse('{}'))
        }
      } else {
        setWorkingBooking(JSON.parse('{}'))
      }
    }
  }, [])

  return (
    <>
      {(frame || workingBooking != undefined) && (
        <BookingFormTimetableProvider>
          <BookingWizardForm
            step={step}
            bookingType={bookingType}
            frame={frame}
            loadedWorkingBooking={workingBooking}
            previousRequest={previousRequest}
            initialValuesBooking={initialValuesBooking}
            initialValuesDetails={initialValuesDetails}
          />
        </BookingFormTimetableProvider>
      )}
    </>
  )
}
